import store from "@/store";
import {getAction} from "@/api/manage";

export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  let event = document.createEvent("HTMLEvents");
  event.initEvent("resize", true, true);
  event.eventType = "message";
  window.dispatchEvent(event);
}

/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
export function filterObj(obj) {
  if (!(typeof obj === "object")) {
    return;
  }

  for (var key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === null || obj[key] === undefined || obj[key] === "")
    ) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * 时间格式化
 * @param value
 * @param fmt
 * @returns {*}
 */
export function formatDate(value, fmt) {
  var regPos = /^\d+(\.\d+)?$/;
  if (regPos.test(value)) {
    // 如果是数字
    let getDate = new Date(value);
    let o = {
      "M+": getDate.getMonth() + 1,
      "d+": getDate.getDate(),
      "h+": getDate.getHours(),
      "m+": getDate.getMinutes(),
      "s+": getDate.getSeconds(),
      "q+": Math.floor((getDate.getMonth() + 3) / 3),
      S: getDate.getMilliseconds(),
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    }
    for (let k in o) {
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
      }
    }
    return fmt;
  } else {
    // TODO
    value = value.trim();
    return value.substr(0, fmt.length);
  }
}

/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) =>
    Math.floor(Math.random() * (max - min + 1) + min);
  if (arguments.length === 1) {
    let [length] = arguments;
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    );
    return parseInt(nums.join(""), 10);
  } else if (arguments.length >= 2) {
    let [min, max] = arguments;
    return random(min, max);
  } else {
    return Number.NaN;
  }
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) {
    length = 1;
  }
  if (!chats) {
    chats = "0123456789qwertyuioplkjhgfdsazxcvbnm";
  }
  let str = "";
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1);
    str += chats[num];
  }
  return str;
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID() {
  let chats = "0123456789abcdef";
  return randomString(32, chats);
}

/**
 * 下划线转驼峰
 * @param string
 * @returns {*}
 */
export function underLine2CamelCase(string) {
  return string.replace(/_([a-z])/g, function(all, letter) {
    return letter.toUpperCase();
  });
}

/**
 * 获取字符串的长度ascii长度为1 中文长度为2
 * @param str
 * @returns {number}
 */
export const getStrFullLength = (str = "") =>
  str.split("").reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1;
    }
    return pre + 2;
  }, 0);

/**
 * 给定一个字符串和一个长度,将此字符串按指定长度截取
 * @param str
 * @param maxLength
 * @returns {string}
 */
export const cutStrByFullLength = (str = "", maxLength) => {
  let showLength = 0;
  return str.split("").reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1;
    } else {
      showLength += 2;
    }
    if (showLength <= maxLength) {
      return pre + cur;
    }
    return pre;
  }, "");
};

/**
 * 清理空值，对象
 * @param children
 * @returns {*[]}
 */
export function filterEmpty(children = []) {
  return children.filter((c) => c.tag || (c.text && c.text.trim() !== ""));
}

/**
 * *29682905.000
 * @num 后台传过来的百万股数据
 * 转换股票信息百万股
 */
export function changeStock(num) {
  // var str = (num.toString()).slice(0, (num.toString().length - 4))
  // return Number(str).toFixed(3);
  num = Number(num);
  if (num === 0) {
    return num + "";
  } else if (num > 1 && num < 10000) {
    return num + "";
  } else {
    return (num / 1000000).toFixed(2);
  }
}

/**
 * textarea文本处理。对于换行符，手动添加<br/>标签
 */
export function textToTextarea(text) {
  return text.replace("\n", "<br/>");
}

export function setCrumbs(url) {
  let treeList = store.state.navTree;
  let stock = [];
  let going = true;
  let warker = function(arr, url) {
    arr.forEach((item) => {
      if (!going) return;
      // stock.push(item[store.state.navLanguageKey]);
      stock.push(item);
      if (item.crumb == url) {
      console.log(item)
        going = false;
      } else if (item.childList) {
        warker(item.childList, url);
      } else {
        stock.pop();
      }
    });
    if (going) stock.pop();
  };
  warker(treeList, url);
  return stock;
}

/**
 * 获取广告列表
 * @param typeCode
 * @param language
 */
export function getAdvertiseList(records, language) {
    if (!language || language == '') {
      language = 'zh'
    }

    let result = []

    if (language === 'zh') {
      result = records.map(item => {
        return {url: item.url === '' ? "javascript:void(0)" : item.url, img: item.sampleImg};
      } );
    } else if (language === 'en') {
      result = records.map(item => {
        return {url: item.url === '' ? "javascript:void(0)" : item.url, img: item.enImg};
      });
    } else if (language === 'tr') {
      result = records.map(item => {
        return {url: item.url === '' ? "javascript:void(0)" : item.url, img: item.tranditionalImg};
      });
    }

    return result;
}
