<template>
  <div id="app" v-if="isShow">
    <div class="coonmon">
      <!-- 导航 -->
      <!-- 导航跳转 TopNavFixed-->
      <header
        class="jv-fixed"
        :style="{
          position: TopNavFixed ? 'fixed' : '',
          top: TopNavFixed ? '0' : '',
          zIndex: TopNavFixed ? '999' : '',
        }"
      >
        <hgroup>
          <a href="/"
            ><img src="./assets/images/coomon/logo.png" alt="錦欣生殖"
          /></a>
        </hgroup>
        <nav :class="navisshow ? 'zhankaiNav' : ''" v-if="!isSafari">
          <!-- 首页 -->
          <div v-for="(item, index) in navTree" :key="index">
            <a :href="jumpUrl(item)" @click="cleanPageNum(item)"
              >{{ item[navLanguageKey]
              }}<i v-if="item.childList.length" class="fa fa-angle-down"></i
            ></a>
            <div v-if="item.childList.length">
              <a
                v-for="(it, index) in item.childList"
                :key="index"
                :href="jumpUrl(it)"
                >{{ it[navLanguageKey] }}</a
              >
            </div>
          </div>

          <div class="tran">
            <a href="javascript:;" class="lan">
              <span>中</span>
              <span>A</span>
            </a>
            <div class="lan-drop">
              <a
                v-if="this.$store.state.nowlanguage !== 'zh'"
                @click="langChange('zh')"
                >简体</a
              >
              <a
                v-if="this.$store.state.nowlanguage !== 'en'"
                @click="langChange('en')"
                >EN</a
              >
              <a
                v-if="this.$store.state.nowlanguage !== 'tr'"
                @click="langChange('tr')"
                >繁体</a
              >
            </div>
            <a
              href="javascript:;"
              class="fa fa-bars"
              data-stute="c"
              @click="navisshow = !navisshow"
            ></a>
          </div>
        </nav>
        <!-- safari浏览器 -->
        <nav :class="navisshow ? 'SafarizhankaiNav' : ''" v-if="isSafari">
          <!-- 首页 -->
          <div v-for="(item, index) in navTree" :key="index">
            <a :href="jumpUrl(item)" @click="cleanPageNum(item)"
              >{{ item[navLanguageKey]
              }}<i v-if="item.childList.length" class="fa fa-angle-down"></i
            ></a>
            <div v-if="item.childList.length">
              <a
                v-for="(it, index) in item.childList"
                :key="index"
                :href="jumpUrl(it)"
                >{{ it[navLanguageKey] }}</a
              >
            </div>
          </div>

          <div class="tran">
            <a href="javascript:;" class="lan">
              <span>中</span>
              <span>A</span>
            </a>
            <div class="lan-drop">
              <a
                v-if="this.$store.state.nowlanguage !== 'zh'"
                @click="langChange('zh')"
                >简体</a
              >
              <a
                v-if="this.$store.state.nowlanguage !== 'en'"
                @click="langChange('en')"
                >EN</a
              >
              <a
                v-if="this.$store.state.nowlanguage !== 'tr'"
                @click="langChange('tr')"
                >繁体</a
              >
            </div>
            <a
              href="javascript:;"
              class="fa fa-bars"
              data-stute="c"
              @click="navisshow = !navisshow"
            ></a>
          </div>
        </nav>
        <!-- 1024分辨率以下的语言切换 -->
        <div>
          <a
            href="javascript:;"
            class="lan jv-lan"
            @click="changesmall"
            id="smallnav"
          >
            <span>中</span>
            <span>A</span>
          </a>
          <div
            class="lan-drop"
            id="smallsecond"
            :style="{ display: smalllanguagebtn ? '' : 'none' }"
          >
            <a
              v-if="this.$store.state.nowlanguage !== 'zh'"
              @click="langChange('zh')"
              >简体</a
            >
            <a
              v-if="this.$store.state.nowlanguage !== 'en'"
              @click="langChange('en')"
              >EN</a
            >
            <a
              v-if="this.$store.state.nowlanguage !== 'tr'"
              @click="langChange('tr')"
              >繁体</a
            >
          </div>
          <a
            href="javascript:;"
            :class="navisshow ? 'fa fa-remove' : 'fa fa-bars'"
            data-stute="c"
            @click="changenav()"
          ></a>
        </div>
      </header>
      <div style="min-height: 72vh"><router-view /></div>
      <!-- 页脚 -->
      <footer>
        <div>
          <dl class="flogo">
            <dt>
              <i></i>
              {{ $t("index.footer.JinXin") }}
            </dt>
            <dd>{{ $t("index.footer.Address") }}</dd>
          </dl>
          <dl class="tel">
            <!-- <a href="tel:400-042-6868"
              ><img :src="$t('index.footer.telimg')" alt="24小时热线"
            /></a> -->
          </dl>
          <!-- 微信服务号 -->
          <dl class="wx">
            <dt>{{ $t("index.footer.WeChat Number") }}</dt>
            <dd>
              <img
                src="./assets/images/coomon/prcode.jpg"
                alt="微信服务号二维码"
              />
            </dd>
            <dd class="fd2">{{ $t("index.footer.guanzhujinxin") }}</dd>
          </dl>

          <dl class="wx" style="text-align: center;">
            <dt>{{ $t("index.footer.Investor Relations") }}</dt>
            <dd>
              <img
                src="./assets/images/coomon/touzi.jpg"
                alt="微信服务号二维码"
                style="max-width: 135px;
                        border-radius: 0.5em;
                      "
              />
            </dd>
            <dd class="fd2">{{ $t("index.footer.touzizheguanxi") }}</dd>
          </dl>
        </div>
        <ul>
          <li class="footer_icon">
            <router-link to="/contactus/counsel"
              ><i class="fa fa-weixin fa-lg" aria-hidden="true"></i
            ></router-link>
            <router-link to="/contactus/counsel"
              ><i class="fa fa-qq fa-lg" aria-hidden="true"></i
            ></router-link>
            <router-link to="/contactus/counsel"
              ><i class="fa fa-weibo fa-lg" aria-hidden="true"></i
            ></router-link>
          </li>
          <li>
            <span>COPYRIGHT © 2018</span>
            <span>四川锦欣生殖医疗管理有限公司</span>
            <span
              ><a
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >蜀ICP备18026631号-2</a
              ></span
            >
          </li>
        </ul>
      </footer>
      <!-- 右侧导航栏 -->
      <!-- 右侧灰色背景 -->
      <div
        :style="languageChange === 'en' ? 'width: 145px;' : 'width: 112px;'"
        id="custSerBg"
        class="custSerBg"
        :class="layoutSidebar == 'right' ? 'custSerBg' : 'custSerBgLeft'"
      ></div>
      <!-- 右侧导航栏 -->
      <div
        class="Right_btn"
        :class="layoutSidebar == 'right' ? 'Right_btn' : 'Right_btn_left'"
      >
        <div
          v-for="(item, index) in sideBarList"
          :key="index + 'key'"
          :class="item.haveRed === '1' ? 'online' : ''"
        >
          <!-- 在线沟通-->
          <a :href="item.url">
            <!--            <i class="fa fa-weixin fa-2x"></i>-->
            <img
              :src="item.icon"
              style="width: 35px; height: 35px; margin: 0 auto"
              v-if="item.isIndex !== 1"
            />
            <img
              :src="require('@//assets/images/coomon/home.png')"
              style="width: 35px; height: 35px; margin: 0 auto"
              v-if="item.isIndex == 1"
            />
            <span>{{ filterLang(item, "Title") }}</span>
          </a>
          <i class="Right_tips" v-if="item.haveRed === '1'">{{
            item.randomNum
          }}</i>
        </div>
        <!--        <div>-->
        <!--          &lt;!&ndash; 关于锦欣 &ndash;&gt;-->
        <!--          <router-link to="/aboutus"-->
        <!--            ><i class="fa fa-file-text-o fa-2x" aria-hidden="true"></i-->
        <!--            ><span>{{ $t("indexnav.About jinxin") }}</span></router-link-->
        <!--          >-->
        <!--        </div>-->
        <!--        <div>-->
        <!--          &lt;!&ndash; 开展业务&ndash;&gt;-->
        <!--          <router-link to="/chapterseven"-->
        <!--            ><i class="fa fa-line-chart fa-2x" aria-hidden="true"></i-->
        <!--            ><span>{{ $t("indexnav.Our Business") }}</span>-->
        <!--          </router-link>-->
        <!--        </div>-->
        <!--        <div>-->
        <!--          &lt;!&ndash; 联系我们 &ndash;&gt;-->
        <!--          <router-link to="/contactus/default"-->
        <!--            ><i class="fa fa-envelope-o fa-2x" aria-hidden="true"></i-->
        <!--            ><span>{{ $t("indexnav.Contact Us") }}</span></router-link-->
        <!--          >-->
        <!--        </div>-->
        <!--        <div id="Right_Home">-->
        <!--          &lt;!&ndash; 网站首页 &ndash;&gt;-->
        <!--          <router-link to="/" v-if="this.$store.state.isindex"-->
        <!--            ><i class="fa fa-home fa-2x" aria-hidden="true"></i-->
        <!--            ><span>{{ $t("indexnav.Home") }}</span></router-link-->
        <!--          >-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
// 样式重置表
import "@/assets/css/reset.css";
// 字体图标库
import "@/assets/css/font-awesome-4.7.0/css/font-awesome.min.css";
import { axios } from "@/utils/request";
import * as utils from "@/utils/util";
import { filterLang } from "@/utils/LangChange";
import { getAction } from "@/api/manage";
import store from "@/store";
import { setCrumbs, setRouter } from "@/utils/util";

export default {
  metainfo() {
    return {
      meta: this.metadata,
    };
  },
  provide() {
    return {
      setCrumbs: setCrumbs,
      setRouter: setRouter,
    };
  },
  data() {
    return {
      isShow: false,
      // 当前语言
      languageChange: localStorage.getItem("lang"),
      // 是否固定定位于屏幕最上层
      TopNavFixed: false,
      // 1024分辨率点击菜单显示导航
      navisshow: false,
      // 1024分辨率点击语言按钮显示语言选项
      smalllanguagebtn: false,
      layoutSidebar: "right",
      layoutSidebarId: "",
      sideBarList: [],
      navTree: [],
      // navLanguageKey: "",
      url: {
        sideBar: "/jeecg-system/frontend/sideBar/list",
        nav: "/jeecg-system/frontend/topNavigationBar/tree_list",
      },
      isSafari: false, //判定是否是Safari浏览器
    };
  },
  created() {
    this.isSafari = false;
    var issafariBrowser =
      /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if (issafariBrowser) {
      this.isSafari = true;
    }
    this.screenWidth = document.documentElement.clientWidth + "px";
    this.initSideBar();
    this.getLayoutSidebar();
    this.getNavTree();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    this.$nextTick(() => {
      this.isShow = true;
    });
  },
  computed: {
    navLanguageKey() {
      if (this.languageChange == "zh") return "sampleName";
      if (this.languageChange == "tr") return "tranditionalName";
      if (this.languageChange == "en") return "enName";
      if (!this.languageChang) return "sampleName";
    },
    vuexData() {
      return this.$store.state.nowlanguage;
    },
  },
  methods: {
    jumpUrl(item) {
      // if (item.sampleName=='新闻中心') {
      //   sessionStorage.removeItem('currentPage')
      // }
      if (item.jumpType == "article_type")
        return `/article/list?type=${item.articleTypeId}`;
      if (item.jumpType == "in_page") return item.inPage;
      if (item.jumpType == "no") return null;
      if (item.jumpType == "jump_url") return item.jumpUrl;
    },
    cleanPageNum(item) {
      if (item.sampleName == "新闻中心") {
        sessionStorage.removeItem("currentPage");
      }
    },
    // 获取顶部导航
    async getNavTree() {
      const { result, success } = await getAction(this.url.nav);
      console.log(result);
      this.recursionNavTree(result);
      this.navTree = result;
      store.state.navTree = result;
      store.state.navLanguageKey = this.navLanguageKey;
      if (localStorage.getItem("lang")) {
        this.languageChange = localStorage.getItem("lang");
      } else {
        localStorage.setItem("lang", "zh");
        this.languageChange = "zh";
        // /newsinfo?id=1564456183996092418
      }
    },
    // 递归顶部导航
    recursionNavTree(list) {
      for (let i of list) {
        i.crumb = this.jumpUrl(i);
        if (i.childList && i.childList.length) {
          this.recursionNavTree(i.childList);
        }
      }
    },
    // 获取侧边栏位置
    async getLayoutSidebar() {
      const { result, success } = await getAction(this.url.sideBar);
      this.layoutSidebar = result.location;
      this.layoutSidebarId = result.homePageModuleId;
    },
    filterLang,
    // 初始化侧边栏
    initSideBar() {
      let self = this;
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        getAction("/jeecg-system/frontend/sideBar/list", {
          showLocation: "phone",
        }).then((res) => {
          console.log("侧边栏", res);
          if (res.success) {
            self.sideBarList = res.result.list;
            // 针对首页做处理
            if (window.location.pathname !== "/") {
              self.sideBarList.push({
                sampleTitle: "首页",
                tranditionalTitle: "首頁",
                enTitle: "Home",
                url: "/",
                haveRed: "0",
                isIndex: 1,
                sort: 100,
              });
            }

            // 针对随机数做处理
            // if (self.sideBarList instanceof Array && self.sideBarList.length > 0) {
            //   const mathedList = self.sideBarList.filter(item => item.haveRed === '1');
            //   mathedList.forEach(item => {
            //     item.num = utils.randomNumber(1);
            //     if (item.num === 0) {
            //       item.num = 5;
            //     }
            //   })
            // }
          }
        });
      } else {
        getAction("/jeecg-system/frontend/sideBar/list", {
          showLocation: "pc",
        }).then((res) => {
          console.log("侧边栏", res);
          if (res.success) {
            self.sideBarList = res.result.list;
            // 针对首页做处理
            if (window.location.pathname !== "/") {
              self.sideBarList.push({
                sampleTitle: "首页",
                tranditionalTitle: "首頁",
                enTitle: "Home",
                url: "/",
                haveRed: "0",
                isIndex: 1,
                sort: 100,
              });
            }

            // 针对随机数做处理
            // if (self.sideBarList instanceof Array && self.sideBarList.length > 0) {
            //   const mathedList = self.sideBarList.filter(item => item.haveRed === '1');
            //   mathedList.forEach(item => {
            //     item.num = utils.randomNumber(1);
            //     if (item.num === 0) {
            //       item.num = 5;
            //     }
            //   })
            // }
          }
        });
      }
    },
    // 中英文切换
    langChange(command) {
      this.$i18n.locale = command;
      // 设置语言为当前点击的语言
      localStorage.setItem("lang", command);
      this.languageChange = command;
      this.$store.commit("setnowlanguage", { command });
      if (command === "en") {
        this.language = "English";
      } else if (command === "zh") {
        this.language = "中文";
      }
      store.state.navLanguageKey = this.navLanguageKey;
      if (this.$route.path === "/") {
        location.reload();
      }
      this.$router.push("/");

      this.$forceUpdate();
    },
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop, "滚动距离", typeof scrollTop);
      // 滚动页面大于90设置固定定位变量为true
      if (scrollTop >= 90) {
        if (this.TopNavFixed !== true) {
          this.TopNavFixed = true;
        } else {
          return;
        }
      } else if (scrollTop < 90) {
        if (this.TopNavFixed !== false) {
          this.TopNavFixed = false;
        }
      }
    },
    // 改变菜单状态按钮
    changenav() {
      this.navisshow = !this.navisshow;
    },
    changesmall() {
      this.smalllanguagebtn = !this.smalllanguagebtn;
    },
  },
  watch: {
    $route(to, from) {
      let custSerBg = document.getElementById("custSerBg");
      let Rightbtn = document.querySelector(".Right_btn");
      let RightHome = document.getElementById("Right_Home");
      if (to.path === "/") {
        this.$store.state.isindex = false;
        // console.log(this.$refs('custSerBg'));
        // 当页面为首页时,改变右侧导航栏高度
        custSerBg.style.height = 411 + "px";
        Rightbtn.style.height = 411 + "px";
        RightHome.style.display = "none";
      } else {
        // 当页面不是首页时,改变右侧导航栏高度
        this.$store.state.isindex = true;
        custSerBg.style.height = 511 + "px";
        Rightbtn.style.height = 511 + "px";
      }
    },

    vuexData(newValue, oldValue) {
      if (newValue == "zh") {
        this.$router.push("/");
      }
      if (newValue == "tr") {
        this.$router.push("/");
      }
      if (newValue == "en") {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style lang="less">
@import "./components/css/coompont.css";
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.zhankaiNav {
  width: 50%;
  visibility: visible;
}
.SafarizhankaiNav {
  width: 50%;
  visibility: visible;
  height: 80% !important;
}
.banner {
  min-height: 42vh;
  position: relative;
  background-repeat: no-repeat;
  // background-position: center;
  background-size: cover;
  // height: 674px;
}
.banner > img {
  min-height: 42vh;
  width: 100%;
}
.loading_box {
  width: 100%;
  height: 60px;
  background-image: url("./assets/images/loading/20140524124237518.gif") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
// .coonmon{
//   min-height: 72vh;
// }
section {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}
// 全局a标签选中样式
a:hover {
  color: red;
  span {
    color: #666;
  }
}
#app {
  height: 100%;
  width: 100%;
}
.fa-bars:before {
  content: "\f0c9";
}
/* 顶部语言切换 */
.menu {
  display: none;
  margin-right: -24px;
  margin-left: 12px;
  // position: absolute;
  // right: 20px;
}

.lan-drop a {
  color: #000 !important;
  cursor: pointer !important;
}

header {
  padding: 0.6em 3em;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d466e;
}

header hgroup {
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

header hgroup > a {
  flex-basis: 12em;
}

header hgroup > a img {
  margin: 0 0 0 auto;
  max-width: 10em;
  display: block;
}

header a {
  color: #fff;
}

header div > div a:hover {
  color: #e60044;
}

header > div a + a {
  margin-left: 2em;
}

header > div {
  display: none;
}

header > div a.lan {
  width: 2.5em;
  height: 2.5em;
  font-size: 0.8em;
  display: block;
  position: relative;
}

header > div a.lan span {
  width: 1.6em;
  height: 1.6em;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #fff 1px solid;
  position: absolute;
  border-radius: 0.2em;
}

header > div a.lan span:nth-of-type(1) {
  background-color: #2d466e;
  color: #fff;
  left: 0;
  top: 0;
  z-index: 2;
}

header > div a.lan span:nth-of-type(2) {
  background-color: #fff;
  color: #2d466e;
  right: 0;
  bottom: 0;
  z-index: 1;
}

header > div a + a {
  margin-left: 2em;
}

header nav {
  // max-width: 65em;
  max-width: 80em;
  width: auto;
  // flex-grow: 1;
  line-height: 1.2;
  display: flex;
  // justify-content: space-around;
  z-index: 4;
  transition: all 0.3s;
  font-size: 0.85em;
  align-items: center;
}

header nav a {
  // padding: 0.5em 0;
  flex-grow: 1;
  text-align: center;
}

header nav > div {
  position: relative;
  // flex-grow: 1;
  padding: 0.5em 1em;
  text-align: center;
}

header nav > div > div {
  padding: 0.5em 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 2em;
  z-index: 3;
  display: none;
  background-color: #fff;
  opacity: 0.9;
}

header nav > div > div a {
  padding: 0.5em;
  line-height: 1.2;
  color: #000;
  display: block;
  text-align: center;
}

header nav > div:hover:after,
header nav > div:hover div {
  display: block;
}

header nav .tran {
  flex-grow: 0;
}

header nav .tran a.lan {
  width: 2.5em;
  height: 2.5em;
  font-size: 0.8em;
  display: block;
  position: relative;
  margin-left: 2em;
}

header nav .tran a.lan span {
  width: 1.6em;
  height: 1.6em;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #fff 1px solid;
  position: absolute;
  border-radius: 0.2em;
}

header nav .tran a.lan span:nth-of-type(1) {
  background-color: #2d466e;
  color: #fff;
  left: 0;
  top: 0;
  z-index: 2;
}

header nav .tran a.lan span:nth-of-type(2) {
  background-color: #fff;
  color: #2d466e;
  right: 0;
  bottom: 0;
  z-index: 1;
}

header + a img {
  max-width: 100%;
}

header a.fa {
  display: none;
  margin-left: 1em;
}

header a i.fa {
  margin-left: 0.2em;
}

header > div {
  display: none;
}

header nav > div > div.lan-drop {
  left: 0.8em;
  // top: 1.9em;
  top: 3em;
  width: 100%;
}

header div > div.lan-drop-show {
  display: flex !important;
  flex-direction: column;
}

header div > div.lan-drop {
  padding: 0.5em 0;
  width: auto;
  position: absolute;
  right: 2.1em;
  top: 2.9em;
  z-index: 3;
  // display: none;
  background-color: #fff;
  opacity: 0.9;
}

header div > div.lan-drop a {
  padding: 0.5em;
  line-height: 1.2;
  color: #000;
  display: block;
  text-align: center;
  font-size: 0.85em;
  margin-left: 0;
}
@media screen and (min-width: 700px) {
  .banner {
    min-height: 42vh !important;
    background-repeat: no-repeat;
    // background-position: center;
    background-size: cover;
    height: 674px;
  }
}
@media screen and (max-width: 1200px) {
  .banner {
    min-height: 42vh !important;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // height: 674px;
  }
  .banner > img {
    // min-height: 42vh;
    height: 42vh;
  }
  header {
    padding: 0.5em 1em 0.5em 0;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-between;
    position: static;
  }
  header:before {
    display: none;
  }

  header a.fa {
    display: block;
    color: #fff;
  }

  header nav {
    max-height: calc(100vh - 145px) !important;
    margin: 0;
    padding: 1em;
    width: 50%;
    background-color: #f1f1f1;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    z-index: 40;
    overflow-y: scroll;
    border-right: #d1d1d1 1px solid;
    height: 690px;
    top: 48px;
    width: 0%;
    visibility: hidden;
  }

  header nav > * + * {
    border: 0;
    border-top: #ccc 1px dashed;
    width: 100%;
    line-height: 1.5em;
  }

  header nav a {
    margin-top: 0;
    color: #333;
    flex-grow: 0;
  }

  header hgroup > a img {
    max-width: 5em;
  }

  header hgroup > a {
    flex-basis: auto;
    position: relative;
    z-index: 4;
  }

  header nav > div {
    width: auto;
    position: relative;
    z-index: 4;
    flex-grow: 0;
    width: 100%;
    line-height: 1.5em;
  }

  header nav > div a {
    display: block;
    text-align: center;
    position: relative;
    z-index: 5;
    line-height: 1.5em;
  }

  header nav > div div {
    padding: 0;
    left: 0;
    top: 0;
    display: block;
    position: relative;
    z-index: 5;
    width: auto;
    height: auto;
    background-color: transparent;
  }

  header nav > div > div a {
    padding: 0.2em 0;
    line-height: 1.5em;
  }

  header > div {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  header nav .tran {
    display: none;
  }
  #smallsecond {
    right: 3em;
  }
}

@media screen and (max-width: 640px) {
  header nav .tran {
    display: none;
  }

  header > div {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  header > div a.lan {
    width: 2em;
    height: 2em;
  }

  header > div a.lan span {
    font-size: 0.6rem;
  }
  .banner {
    min-height: 24vh;
    > img {
      min-height: 24vh;
    }
  }

  // .banner > img {
  //   height: 32vh!important;
  //   // height: 100%!important;
  // }
  //   .banner span {
  //     font-size: 2rem;
  //   }

  //   .banner span i {
  //     font-size: 1.5rem;
  //   }

  footer > div:nth-of-type(1) {
    flex-direction: column;
  }

  footer > div:nth-of-type(1) dl {
    text-align: center;
  }

  footer > div:nth-of-type(1) dl + dl {
    margin-top: 1em;
  }

  footer > div:nth-of-type(1) dl dt {
    font-size: 1rem;
    font-weight: bold;
  }

  footer > div:nth-of-type(1) dl dd {
    font-size: 0.8rem;
  }

  footer .flogo dt i {
    margin: 0 auto;
  }

  .comm-go-top {
    display: none;
  }

  .comm-inbox {
    display: none;
  }
}
.navchange {
  height: 690px;
  top: 48px;
  width: 50%;
  visibility: visible;
}
// ==
// .coonmon {
//   display: flex;
//   flex-direction: column;
// }
footer {
  padding: 4em 0;
  color: #fff;
  width: 100%;
  flex: 1;
  background-color: #2b426a;
}
article {
  box-sizing: border-box;
}
section {
  box-sizing: border-box;
}
/* 右侧导航栏 */
.Right_btn {
  padding: 1em;
  width: auto;
  font-size: 1rem;
  line-height: 3;
  text-align: center;
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  z-index: 10;
  box-sizing: border-box;
  // height: 547px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  span {
    line-height: 22px;
  }
  // & > div {
  //   margin-top: 1em;
  //   &:first-child {
  //     margin-top: 0;
  //   }
  // }
}
.Right_btn_left {
  right: unset;
  left: 0;
}
.Right_btn > div > a {
  display: flex;
  flex-direction: column;
  padding: 0.8em 0.5em;
  color: #fff;
}
.Right_btn > div > a:hover {
  color: #eee;
}
.custSerBg {
  // width: 100%;
  width: 112px;
  height: 100%;
  position: fixed;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
  box-sizing: border-box;
  border-radius: 1em 0 0 1em;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9;
}
.custSerBgLeft {
  right: unset;
  left: 0;
  border-radius: 0 1em 1em 0;
}
.Right_btn .online .Right_tips {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-size: 0.6rem;
  line-height: 2;
  text-align: center;
  font-style: normal;
  color: #fff;
  background-color: #f00;
  position: absolute;
  right: 2.5em;
  top: 1em;
  line-height: 2em;
  animation: 800ms infinite;
  -webkit-animation: 800ms infinite;
}
/* 在线沟通 闪烁 */
.Right_btn .online {
  position: relative;
}
.Right_btn .online .Right_tips {
  position: absolute;
  right: -2px;
  top: 0px;
  -webkit-animation-name: imageAnim;
  -webkit-animation-duration: 0.7s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: ease;
  -webkit-animation-play-state: running;
}
@-webkit-keyframes imageAnim {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
figure {
  box-sizing: border-box;
}
@media screen and (max-width: 640px) {
  .banner {
    // min-height: 0 !important;
    min-height: 22vh !important;
  }
  .banner img {
    height: 22vh !important;
    // min-height: 0 !important;
  }
  .banner span {
    font-size: 2rem !important;
  }
}
@media screen and (max-width: 768px) {
  .half p {
    margin-top: 2em;
  }
}
@media screen and (max-width: 1024px) {
  // 1024分辨率顶部导航
  .Header_href {
    // height: 100px !important;
    > div:not(.language_box) :not(.menu > a) {
      // display: none;
      margin: 0;
      padding: 1em;
      width: 200px;
      height: 20px;
      background-color: #f1f1f1;
      flex-direction: column;
      justify-content: flex-start;
      position: fixed;
      flex-direction: column;
      left: 0;
      z-index: 10;
      // visibility: hidden;
      overflow-y: scroll;
      border-right: #d1d1d1 1px solid;
    }
    .menu {
      display: block;
      a:hover {
        color: #fff !important;
      }
    }
    display: flex;
    justify-content: end !important;
  }
  // 1024分辨率右侧导航
  .Right_btn {
    position: fixed;
    bottom: 0 !important;
    padding: 0.5em;
    width: 100% !important;
    height: 70px !important;
    transform: translate(0, 0);
    display: flex;
    justify-content: space-around !important;
    flex-direction: row !important;
    padding: 0.5em 0;
    font-size: 0.8rem;
    text-align: center;
    flex-grow: 1;
    span {
      line-height: 22px;
    }
    div {
      margin-top: 0 !important;
      a {
        padding: 0;
      }
    }
  }
  .custSerBg {
    width: 100% !important;
    height: 70px !important;
    left: 0 !important;
    bottom: 0px !important;
    transform: translate(0, 0);
    border-radius: 0.5em 0.5em 0 0 !important;
    background: rgba(0, 0, 0, 0.5);
  }
}
</style>
