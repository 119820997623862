import store from "@/store";

/**
 * 过滤不同语言的数据。 并将\n符号替换为br标签。
 * @param item
 * @param keyVlaue
 * @returns {string}
 */
export function filterLang(item, keyVlaue) {
    let result = "";
    let nowLanguage = store.state.nowlanguage;

    if (!nowLanguage) {
        nowLanguage = 'zh';
    }


    if (nowLanguage == 'zh') {
        result =  item['sample' + keyVlaue];
    }
    else if (nowLanguage == 'en') {
        result = item['en' + keyVlaue];
    }
    else if (nowLanguage == 'tr') {
        result = item['tranditional' + keyVlaue];
    }
    if (result && result != '') {
        result = result.replaceAll("\n", "<br/>");
    }

    return result;
}


/**
 * 过滤不同语言的数据, 并取消换行换行符显示
 * @param item
 * @param keyVlaue
 * @returns {string}
 */
export function filterLangNoWordwrap(item, keyVlaue) {
    let result = "";
    let nowLanguage = store.state.nowlanguage;

    if (!nowLanguage) {
        nowLanguage = 'zh';
    }


    if (nowLanguage == 'zh') {
        result =  item['sample' + keyVlaue];
    }
    else if (nowLanguage == 'en') {
        result = item['en' + keyVlaue];
    }
    else if (nowLanguage == 'tr') {
        result = item['tranditional' + keyVlaue];
    }
    if (result && result != '') {
        result = result.replaceAll("\n", "");
    }

    return result;
}
