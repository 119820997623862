import { Notification } from 'element-ui';
// 两个接口域名
import { axios } from "@/utils/request";
// import { axiosMobile } from '@/utils/requestMobile' // 移动端的接口域名，商品分类


// post method= {post | put}
export function httpActions(url, parameter, method) {
  return axios({
    url,
    method,
    data: parameter,
  });
}

// put
export function putAction(url, parameter) {
  return axios({
    url,
    method: "put",
    data: parameter,
  });
}

// get
export function getAction(url, parameter) {
  return axios({
    url,
    method: "get",
    params: parameter,
  });
}

// post
export function postAction(url, parameter) {
  return axios({
    url,
    method: "post",
    data: parameter,
  });
}

// deleteAction
export function deleteAction(url, parameter) {
  return axios({
    url,
    method: "delete",
    params: parameter,
  });
}



/**
 * 请求
 * @param {请求地址} url
 * @param {请求方式} method
 * @param {请求参数} parameter
 * @param {回调函数} successCallback
 */
export function httpAction(url, method, parameter, successCallback) {
  if (successCallback) {
    if (method === "get" || method === "put" || method === "delete") {
      axios({
        url: url,
        method: method,
        params: parameter,
      }).then((res) => {
        if (res.code === 0 || res.code === 200) {
          if (res.data) {
            successCallback(res.data);
          }
          if (res.result) {
            successCallback(res.result);
          }
        } else {
          if (res.message) {
            Notification.error({ title: '系统提示', message: res.message, duration: 4000 })
          } else {
            Notification.error({ title: '系统提示', message: '系统异常，请联系管理员', duration: 4000 })
          }
        }
      });
    }
    if (method === "post") {
      axios({
        url: url,
        method: method,
        data: parameter,
      }).then((res) => {
        if (res.code === 0 || res.code === 200) {
          successCallback(res.data);
        } else {
          if (res.message) {
            Notification.error({ title: '系统提示', message: res.message, duration: 4000 })
          } else {
            Notification.error({ title: '系统提示', message: '系统异常，请联系管理员', duration: 4000 })
          }
        }
      });
    }
  } else {
    if (method === "get" || method === "put" || method === "delete") {
      return axios({
        url: url,
        method: method,
        params: parameter,
      });
    }
    if (method === "post") {
      return axios({
        url: url,
        method: method,
        data: parameter,
      });
    }
  }
}

/**
 * 文件上传
 * @param {请求地址} url
 * @param {请求方式} method
 * @param {文件流} parameter
 */
export function uploadAction(url, method, parameter) {
  let formdata = new FormData();
  formdata.append("file", parameter);
  if (method === "get" || method === "put" || method === "delete") {
    return axios({
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: method,
      params: formdata,
    });
  }
  if (method === "post") {
    return axios({
      url: url,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      method: method,
      data: formdata,
    });
  }
}


