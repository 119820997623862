<template>
  <p>
    <!-- 跳转 -->
    <a href="/">{{ $t("aboutus.Home") }}</a>
    <!-- {{ $t("aboutus.Corporate Profile") }} -->
    <a
      v-for="(item, index) in setCrumbs($route.fullPath)"
      :key="index"
      :href="item.crumb"
    >
      <span> ></span> {{ item[$store.state.navLanguageKey] }}
    </a>
  </p>
</template>

<script>
export default {
  inject: ["setCrumbs"],
};
</script>
