import Vue from "vue";
import { VueAxios, axios } from "@/utils/request";
import ElementUI from 'element-ui'
// import { Button, CarouselItem, Carousel } from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/i18n/index'
import echarts from "echarts";
import VueViewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/api/manage'
import Components from '@/components/index'
import {getAction} from '@/api/manage'
import jquery from 'jquery'
Vue.prototype.$ = jquery

router.beforeEach((to, from, next) => {
    // if(to.fullPath=='/'){
    //   if(localStorage.getItem('lang')=='en'){
    //     document.title='Home-Jinxin Fertility Group Limited'
    //   }
    //   if(localStorage.getItem('lang')=='en'){
    //     document.title='网站首页-Jinxin Fertility Group Limited'
    //   }
    //   if(localStorage.getItem('lang')=='tr'){
    //     document.title='網站首頁-Jinxin Fertility Group Limited'
    //   }
    // }
  getAction('/jeecg-system/frontend/topNavigationBar/tree_list').then(res=>{
    res.result.map((item)=>{
      if(item.inPage==to.fullPath){
        if(localStorage.getItem('lang')=='en'){
          document.title=item.enName+'-Jinxin Fertility Group Limited'
        }
        if(localStorage.getItem('lang')=='zh'){
          document.title=item.sampleName+'|专注辅助生殖、试管婴儿及其他辅助医疗服务'
          
        }
        if(localStorage.getItem('lang')=='tr'){
          document.title=item.tranditionalName+'|錦欣生殖醫療集團有限公司'
        }}

        if(item.inPage===null && to.query.type===item.articleTypeId){
          if(localStorage.getItem('lang')=='en'){
            document.title=item.enName+'-Jinxin Fertility Group Limited'
          }
          if(localStorage.getItem('lang')=='zh'){
            document.title=item.sampleName+'|专注辅助生殖、试管婴儿及其他辅助医疗服务'
            
          }
          if(localStorage.getItem('lang')=='tr'){
            document.title=item.tranditionalName+'|錦欣生殖醫療集團有限公司'
            
          }
        }
        if(item.childList){
          item.childList.map(r=>{
            if(localStorage.getItem('lang')=='en' && r.inPage==to.fullPath){
              console.log(r);
              document.title=r.enName+'-Jinxin Fertility Group Limited'
            }
            if(localStorage.getItem('lang')=='zh' && r.inPage==to.fullPath){
              document.title=r.sampleName+'|专注辅助生殖、试管婴儿及其他辅助医疗服务'
              
            }
            if(localStorage.getItem('lang')=='tr' && r.inPage==to.fullPath){
              document.title=r.tranditionalName+'|錦欣生殖醫療集團有限公司'
              
            }
          })
        }
        // if(to.fullPath.includes('/article/list')){

        //   if(localStorage.getItem('lang')=='en' ){
        //     document.title=item.enName+'-Jinxin Fertility Group Limited'
        //   }
        //   if(localStorage.getItem('lang')=='zh'){
        //     document.title=item.sampleName+'|专注辅助生殖、试管婴儿及其他辅助医疗服务'
            
        //   }
        //   if(localStorage.getItem('lang')=='tr'){
        //     document.title=item.tranditionalName+'|錦欣生殖醫療集團有限公司'
            
        //   }
          
        // }
      // }else if(item.inPage==null){
      //   if(localStorage.getItem('lang')=='en'){
      //     document.title=item.enName+'-Jinxin Fertility Group Limited'
      //     debugger
      //   }
      //   if(localStorage.getItem('lang')=='zh'){
      //   }
      //   if(localStorage.getItem('lang')=='tr'){
      //   }
      // }
    })
  })
  if (to.meta.content) {
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  // console.log(to);
  next()
});

Vue.config.productionTip = false;
Vue.use(axios);
Vue.use(VueAxios);
Vue.use(ElementUI);
Vue.use(echarts)
Vue.use(VueViewer)
Vue.use(Components)
// console.log(process.env.VUE_APP_BASE_API);
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
