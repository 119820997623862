// import zhLocale from 'element-ui/lib/locale/lang/en'

export default {
  lang: "繁体",
  // 首页公共导航
  indexnav: {
    // 首页按钮
    了解更多锦欣动态: "瞭解更多錦欣動態",
    了解招聘詳情: "了解招聘詳情",
    Home: "網站首頁", // 网站首页
    // --------------------------------------------------------

    "About jinxin": "關於錦欣", // 关于锦欣
    "Corporate Profile": "企業簡介", // 关于锦欣下拉菜单--企业简介
    Milestones: "裏程碑", //  关于锦欣下拉菜单--里程碑
    "Awards & Recognitions": "企業榮譽", // 关于锦欣下拉菜单--企业荣誉
    CSR: "社會責任", // 关于锦欣下拉菜单--社会责任
    executives: "高管信息", // 高管信息
    // --------------------------------------------------------
    "Our Medical Institutions": "旗下醫院", // 旗下医院
    "Our Business": "開展業務", // 开展业务
    "News Center": "新聞中心", // 新闻中心
    Careers: "人才招聘", // 人才招聘
    // 投资者关系 --------------------------------------------------------
    "Investor Relations": "投資者關係", // 投资者关系
    "Corporate Information": "公司資料", // 投资者关系下拉菜单--公司资料
    "Listing Documents": "上市文件", // 投资者关系下拉菜单--上市文件
    "Financial Reports": "財務報告", // 投资者关系下拉菜单--财务报告
    "Corporate Presentation": "公司推介材料", // 投资者关系下拉菜单--公司推介材料
    "Stock Chart": "股票信息", // 投资者关系下拉菜单--股票信息
    "IR Calendar": "投資者關係日曆", // 投资者关系下拉菜单--投资者关系日历
    "Press Release": "新聞稿", // 投资者关系下拉菜单--新闻稿
    "Corporate Governance": "企業管治", // 投资者关系下拉菜单--企业管治
    "Announcements and Circulars": "公告及通函", // 投资者关系下拉菜单--公告及通函
    "Email Subscription": "郵件訂閱", // 投资者关系下拉菜单--邮件订阅
    "Investor Enquiries": "投資者查詢", // 投资者关系下拉菜单--投资者查询

    // 联系我们--------------------------------------------------------

    "Contact Us": "聯繫我們", // 联系我们
    "Customer Enquiries": "客戶諮詢", // 联系我们下拉菜单--客户咨询
    "Follow us": "關注我們", // 联系我们下拉菜单--关注我们

    // 页面右侧导航--------------------------------------------------------

    Online: "在線溝通", // 在线沟通
    电话咨询: "电话咨询", // 电话咨询
  },
  // 首页轮播图
  indexbanner: [
    { banner: require("../assets/images/banner/banner_00.jpg") },
    { banner: require("../assets/images/banner/banner_01.jpg") },
    { banner: require("../assets/images/banner/banner_02.gif") },
  ],
  // 首页
  index: {
    jieshao:
      "憑借數十年來的經驗，錦欣生殖已發展成為中美領先的輔助生殖服務 機構，並且不斷地擴大我們的網絡以提供越來越高價值的高端服務，集團旗下包括成都西囡、深圳中山、美國 HRC Fertility 三大輔助生殖技術中心。",
    aboutusimg: require("../assets/images/index/tr_index_03.jpg"), // 关于我们图片
    "Learn more": "瞭解更多 more", // 了解更多
    "Our Medical Institutions": "旗下醫院", // 旗下医院标题
    // 医院列表
    hospitalsList: [
      {
        id: 0,
        name: "成都西囡婦科醫院",
        img: require("../assets/images/index/1-21042109311SD.jpg"),
        address: "四川成都",
        href: "http://www.cdxnfk.com/",
        show: true,
      },
      {
        id: 1,
        name: "成都市錦江區婦幼保健院生殖中心",
        img: require("../assets/images/index/1-210421101F0522.jpg"),
        address: "四川成都",
        href: "http://www.jjqfybjy.com/",
        show: false,
      },
      {
        id: 2,
        name: "深圳中山泌尿外科醫院",
        img: require("../assets/images/index/1-21042110154D48.jpg"),
        address: "廣東·深圳",
        href: "https://www.zsmw.net/",
        show: false,
      },
      {
        id: 3,
        name: "武漢錦欣中西醫結合婦產醫院",
        img: require("../assets/images/index/hosp_img_03.jpg"),
        address: "武漢",
        href: "http://www.wuhanjinxin.com/",
        show: false,
      },
      {
        id: 4,
        name: "美國HRC",
        img: require("../assets/images/index/1-2104211023222H.jpg"),
        address: "[美國·加利福尼亞州]",
        href: "http://www.hrc-fertility.net/",
        show: false,
      },
      {
        id: 5,
        name: "美國HRC東南亞中心（錦瑞醫療中心）",
        img: require("../assets/images/index/1-210323120H40-L.jpg"),
        address: "老挝",
        href: "http://www.jinruifertility.cn/",
        show: false,
      },
    ],
    // 新闻中心
    "News Center": "新聞中心", // 新闻中心
    Careers: "人才招聘", // 人才招聘
    CareersMsg: {
      // 人才招聘下面的标语
      msg1:
        "我們尊重每個員工所獨具的價值，鼓勵員工價值與企業價值共同成長；我們發掘每個員工所內涵的潛能，培訓、塑造、激活員",
      msg2:
        "工的職場生命力；我們敬佩每個員工對夢想的激情，養成每個員工的職業精神和專業素養，成就每一個大小夢想！",
      msg3: "我們歡迎每一個渴望踐行夢想，助力新生孕育，守護父母微笑的你！",
    },
    footer: {
      JinXin: "錦欣生殖", // 名字
      Address: "地址：中國成都市錦江區靜沙北路301號", // 地址
      telimg: require("../assets/images/coomon/tr_index-tel-49.jpg"), // 24小时热线
      "WeChat Number": "微信服務號", // 微信服务号
      guanzhujinxin: "關注錦欣生殖", // 关注锦欣生殖

      "Investor Relations": "投資者關係", // 投资者关系
      touzizheguanxi: "錦欣生殖投資者關係", // 锦欣生殖投资者关系
    },
  },
  // 关于我们 ----------------------------------------------------------------------
  aboutus: {
    //高管信息
    公司管理团队: "公司管理團隊",
    "About Us": "關於我們", // 关于我们标题
    // ---跳转
    Home: "網站首頁", // 返回首页
    "Corporate Profile": "企業簡介", // 企业简介
    // ---企业简介内容
    msg1:
      "自1951年發展至今，錦欣生殖已發展成為中美領先的輔助生殖服務機構，並且不斷地擴大我們的網絡以提供越來越高價值的高端服務，集團旗下包括成都西囡、深圳中山、武漢錦欣、美國HRC Fertility及錦瑞醫療中心（美國HRC東南亞中心） 五大輔助生殖技術中心。", // 内容1
    msg2:
      "本集團成立可追溯至 2003 年， 當時資深及專責的醫生及管理人員團隊發願在四川成都成立錦江 IVF 中心，以專注於輔助生殖服務及相關治療。深圳中山醫院於 2004 年 5 月由多名個人創辦人（包括我們高級管理層成員之一曾勇先生）成立。深圳中山醫院提供輔助生殖及其他輔助醫療服務，並協助深圳首個透過IVE-ET技術及IVF配合ICSI技術的嬰兒誕生。", // 内容2
    msg3:
      "根據弗若斯特沙利文報告， 我們中國網絡內的輔助生殖醫療機構於 2018 年在中國輔助生殖服務市場中排名第三，進行了 20,958 個體外受精（「IVF」）取卵周期，市場份額約為 3.1%。根據相同指標，該等機構亦在 2018 年中國非國有輔助生殖服務機構中排名第一。", // 内容3
    msg4:
      "本集團通過收購管理 HRC Medical 的 HRC Management，使我們增強了為國際患者（尤其是來自中國的患者）提供高價值輔助生殖服務的能力 HRC Fertility 為一間擁有逾30年經驗的美國領先輔助生殖服務全方位服務機構。 除我們在中國提供的類似 IVF 服務外，亦提供植入前遺傳篩查（「PGS」）服務及通過第三方診所或機構提供植入前遺傳學診斷（「PGD」）服務。按 2017 年為前往美國治療的中國出境輔助生殖服務患者提供的IVF 治療周期計， HRC Fertility 在美國所有輔助生殖服務機構中排名第一。", // 内容3
    // ---医院列表
    hospitals: {
      "Chengdu Xinan Hospital": "成都西囡婦科醫院", // 成都西囡妇科医院
      "Shenzhen Zhongshan Hospital": "深圳中山泌尿外科醫院", // 深圳中山泌尿外科医院
      "Wuhan Jinxin Hospital": "武漢錦欣中西醫結合婦產醫院", // 武汉锦欣中西医结合妇产医院
      "HRC Medical": "美國HRC Fertility", // 美国HRC Fertility
      "HRC Southeast Asia Center": "錦瑞醫療中心(美國HRC東南亞中心)", // 锦瑞医疗中心(美国HRC东南亚中心)
    },
    // ---四个行业第一
    fourTitle: "四個行業「第一」 鑄造金字招牌", // 标题
    info1: "亞洲第一家專業化輔助生殖服務的上市公司",
    info2: "非國有輔助生殖服務機構市場占有率第一",
    info3: "美國西部市場占有率第一",
    info4: "中國赴美患者服務數量美國第一",
    // ---全球知名品牌 树立行业标杆
    flagTitle: "全球知名品牌 樹立行業標杆", // 标题
    flageInfo1: "錦欣生殖集團專注於輔助生殖服務及相關治療。", // 内容1
    flageInfo2:
      "錦欣生殖於2019年在四川省、深圳市及美國西部地區的輔助生殖服務市場中均排名第一，進行了27854個體外受精（IVF）取卵周期。",
    // 四个优势
    "70 Years of Jinxin Medical Quality Assurance": "70年錦欣婦兒經驗", // 70年锦欣妇儿经验
    "The Largest VIP Medical Service System": "規模較大的VIP醫療服務體系", // 规模较大的VIP医疗服务体系
    "High success rate of 20-year patient reputation accumulation":
      "20年患者口碑積澱的高成功率", // 20年患者口碑积淀的高成功率
    "First listed stock of assisted reproductive service (ARS) in China":
      "國內輔助生殖第一股", // 国内辅助生殖第一股
    "International Quality and the Largest Nationwide VIP Medical Service System":
      "規模較大且具有國際化的醫療服務體系", // 规模较大且具有国际化的医疗服务体系
    "BESPOKE MEDICAL PLAN": "定製醫療方案", // 定制医疗方案
    "Meet diversified demands": "滿足多樣就診需求", // 满足多样就诊需求

    "VIP QUICK ACCESS SERVICE": "尊享便捷服務", // 尊享便捷服务
    "24hour":
      "24小時診前醫療諮詢、VIP專屬電梯、高級定製營養膳食、專屬停車場、一對一專屬醫師、其他附加服務", // 24小时诊前医疗咨询、VIP专属电梯、高级定制营养膳食、专属停车场、一对一专属医师、其他附加服务
    "PERSONAL COUNSELLING": "全程貼身諮詢", // 全程贴身咨询
    "Personalized exclusive VIP service": "個性私享的尊貴服務", // 个性私享的尊贵服务
    "CELEBRITY-CLASS DIAGNOSIS": "明星級醫生診療", // 明星级医生诊疗
    clinical:
      "院內臨床經驗最為豐富的專家、服務經驗最為豐富的醫療助理級個案管理師服務", // 院内临床经验最为丰富的专家、服务经验最为丰富的医疗助理级个案管理师服务
    // 高成功率
    Hight: "高成功率",
    ensure: "20年輔助生殖領域質量保證", // 20年辅助生殖领域质量保证
    Experts: "中美高年資權威專家", // 中美高年资权威专家
    laboratory: "國際高標準胚胎實驗室", // 国际高标准胚胎实验室
  },
  // 里程碑
  milestone: {
    // 跳转
    Home: "網站首頁", // 网站首页
    "About JinXin": "關於錦欣", // 关于锦欣
    Milestones: "里程碑", // 里程碑
    // 好孕一站式医疗管理服务
    management: "好孕一站式醫療管理服務",
    //  全球领先辅助生殖机构
    leading: "全球領先輔助生殖機構",
    // 历程
    info1: {
      year: "2020年", // 年份
      infor:
        "收購武漢黃浦中西醫結合婦產醫院， 更名為武漢錦欣中西醫結合婦產醫院", // 内容
    },
    info2: {
      year: "2020年", // 年份
      infor: "在老撾成立美國HRC東南亞中心", // 内容
    },
    info3: {
      year: "2019年", // 年份
      infor: "錦欣生殖於2019年6月25日在香港掛牌上市", // 内容
    },
    info4: {
      year: "2018年", // 年份
      infor:
        "信銀投資、紅杉資本中國、Southern Creation 及WuXi AppTec 以首次公開發售前投資者身份投資本集團", // 内容
    },
    info5: {
      year: "2018年", // 年份
      infor: "收購位於美國洛杉磯最大的生殖中心-美國加州HRC生殖中心", // 内容
    },
    info6: {
      year: "2017年", // 年份
      infor: "收購深圳中山泌尿外科醫院成立", // 内容
    },
    info7: {
      year: "2013年", // 年份
      infor: "成都西囡婦科醫院", // 内容
    },
    info8: {
      year: "2000年", // 年份
      infor: "生殖中心正式成立", // 内容
    },
    info9: {
      year: "1999年", // 年份
      infor: "不孕不育門診成立", // 内容
    },
    info10: {
      year: "1951年", // 年份
      infor: "成都市第二區婦幼保健站成立", // 内容
    },
  },
  // 社会责任----------------------------------------------------------------------
  responsibility: {
    // 跳转
    Home: "網站首頁", // 网站首页
    "About JingXin": "關於錦欣", // 关于锦欣
    "Social Responsibility": "社會責任", // 社会责任
    // 选择年份'Select Year'
    "Select Year": "选择年份",
    // 国HRC东南亚中心（锦瑞医疗中心）
    "HRC Southeast Asia Center": "国HRC东南亚中心（锦瑞医疗中心）",
    //  共同管理
    "Jointly-managed": "共同管理",
  },
  //  企业荣誉
  //  企业荣誉
  Honor: {
    // 荣誉列表
    title: "項目名稱：",
    certification: "認證：",
    time: "頒發時間：",
    institutions: "頒發機構：",
  },
  // 旗下医院
  hospitals: {
    title: "旗下醫院", // 标题
    "Self-owned": "自有醫院", // 自有医院
    // 国HRC东南亚中心（锦瑞医疗中心）
    "HRC Southeast Asia Center": "美國HRC東南亞中心（錦瑞醫療中心）",
    //  共同管理
    "Jointly-managed": "共同管理",
  },
  // 开展业务
  "Our Business": {
    businessList: [
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_03.jpg"),
        // 标题
        title: "輔助醫療服務",
        // 内容
        info:
          "在深圳中山醫院，我們也於婦科、泌尿外科及內科領域提供醫療服務。該等醫療服務被指定為我們的配套醫療服務。",
      },
      // 列表2
      {
        // 图片
        img: require("@/assets/images/chapterseven/bjkgl.jpg"),
        // 标题
        title: "管理服務",
        // 内容
        info:
          " 於往績記錄期，我們根據分別與成都市錦江區婦幼保健院及四川錦欣婦女兒童醫院訂立的學科共建和合作協議向錦江生殖中心及錦欣生育中心（四川錦欣婦女兒童醫院生殖健康與不孕症門診）提供合作管理服務收取管理費。2018 年3 月，我們終止向錦欣生育中心提供的管理服務，以優化我們的業務結構及資源，隨後錦欣生育中心的業務及有關學科共建和合作協議亦終止。我們於2018 年12 月收購HRC Management，其自2017 年7 月起一直根據管理服務協議在美國向HRC Medical 提供管理服務。",
      },
      // 列表3
      {
        // 图片
        img: require("@/assets/images/chapterseven/bfzyl.jpg"),
        // 标题
        title: "輔助生殖服務",
        // 内容
        info:
          " 我們主要為患者提供兩種治療方案： (i) 人工授精(AI)，可以通過夫精(AIH) 或供精(AID) 進行；及(ii) IVF 技術，通過常規體外受精和胚胎移植(IVF-ET) 或通過卵胞漿內單精子註射(ICSI) 進行 IVF 來導致受精。於往績記錄期，我們主要提供常規IVF-ET 服務。此外，我們亦提供營養指導、中醫治療及心理輔導等相關服務，以支持輔助生殖服務。 ",
      },
    ],
  },
  // 公司资料------------------------------------------------------------
  "Corporate Information": {
    // 左侧列表
    Listleft: [
      // 注册办事处
      {
        // 标题
        title: "注册办事处",
        // 内容
        info: [
          "Cricket Square, Hutchins Drive",
          "P.O. Box 2681, Grand Cayman",
          "KY1-1111",
          "Cayman Islands",
        ],
      },
      // 中国总部及主要营业地点
      {
        // 标题
        title: "中国总部及主要营业地点",
        // 内容
        info: ["中国四川省", "成都市锦江区", "静沙北路301号"],
      },
      // 香港主要营业地点
      {
        // 标题
        title: "香港主要营业地点",
        // 内容
        info: ["香港", "西九龙柯士甸道西1号", "环球贸易广场19号1907B"],
      },
      // 公司网站
      {
        // 标题
        title: "公司网站",
        // 内容
        info: ["www.jxr-fertility.com"],
      },
      // 联席公司秘书

      {
        // 标题
        title: "联席公司秘书",
        // 内容
        info: ["翟扬扬女士", "李国辉先生"],
      },
      // 授权代表
      {
        // 标题
        title: "授权代表",
        // 内容
        info: ["李国辉先生"],
      },
      // 审核及风险管理委员会
      {
        // 标题
        title: "审核及风险管理委员会",
        // 内容
        info: [
          "叶长青先生（主席）",
          "方敏先生",
          "胡喆女士",
          "庄一强博士",
          "王啸波先生",
        ],
      },
    ],
    // 右侧列表
    Listright: [
      // 薪酬委员会
      {
        // 标题
        title: "薪酬委员会",
        // 信息
        info: [
          " 庄一强博士（主席）",
          "董阳先生",
          "方敏先生",
          "王啸波先生",
          "叶长青先生",
        ],
      },
      // 提名委员会
      {
        // 标题
        title: "提名委员会",
        // 信息
        info: ["钟勇先生（主席）", " 庄一强博士", "王啸波先生", "叶长青先生"],
      },
      // 战略决策委员会
      {
        // 标题
        title: "战略决策委员会",
        // 信息
        info: [
          "钟勇先生（主席）",
          "John G. Wilcox 医生",
          "董阳先生",
          "方敏先生",
          " 李建伟先生",
        ],
      },
      // 医疗品质控制与科研发展委员会
      {
        // 标题
        title: "医疗品质控制与科研发展委员会",
        // 信息
        info: ["John G. Wilcox 医生", "庄一强博士"],
      },
      // 香港证券登记处
      {
        // 标题
        title: "香港证券登记处",
        // 信息
        info: ["香港中央证券登记有限公司"],
      },
    ],
  },
  // 上市文件
  "Listing Documents": {
    // 点击图片跳转地址
    href:
      "https://files.services/files/485/2019/0613/20190613065812_03522122_sc.pdf",
    img:
      "https://files.services/files/485/2019/0613/20190613070000_50449292_sc.png",
  },
  // 股票信息
  stockmsg: {
    日期: "日期",
    时间: "时间",
    港币: "港币",
    "升/跌": "升/跌",
    "升/跌(%)": "升/跌 (%)",
    前收市价: "前收市价",
    开盘价: "开盘价",
    今日最高: "今日最高",
    今日最低: "今日最低",
    今日波幅: "今日波幅",
    "52周波幅": "52周波幅",
    市值: "市值",
    交易单位: "交易单位",
    成交量: "成交量",
    成交额: "成交额",
    目前市盈率: "目前市盈率",
    周息率: "周息率",
    百万股: "百万股",
    百万: "百万",
    // 趋势图
    "1个月": "1个月",
    "3个月": "3个月",
    "1年": "1年",
    今年: "今年",
    全部: "全部",
    亿: "亿",
  },
  // 企业管治
  corporategovernance: {
    董事名单: "董事名单和他们的地位和作用",
    董事名单与其角色和职能: "董事名单与其角色和职能",
    薪酬委员会的职权范围: "薪酬委员会的职权范围",
    薪酬委员会职权范围: "薪酬委员会职权范围",
    提名委员会的职权范围: "提名委员会的职权范围",
    审核委员会的职权范围: "审核委员会的职权范围",
    审核及风险管理委员会职权范围: "审核及风险管理委员会职权范围",
  },
  // 邮件订阅
  email: {
    // 标语
    title: "如果您希望收到本公司最新公佈的資訊，請填妥以下表格",
    From: {
      //    姓名
      姓名: "姓名",
      // 邮箱地址
      电邮地址: "電郵地址",
      // 公司
      公司: "公司",
      // 职位
      职位: "職位",
      // 地区
      地区: "地區",
      // 选择地区占位符
      请选择: "请选择",
      // 联络电话
      联络电话: "聯絡電話",
      // 内线
      内线: "内线",
      // 语言
      语言: "語言",
      // 选项
      英文: "英文",
      中文: "中文",
      "中文&英文": "中文&英文",
      // 提交
      提交: "提交",
      // 验证码
      验证码: "驗證碼",
    },
  },
  // 投资者关系日历
  touzizheguanxirili: {
    日期: "日期",
    活动: "活動",
    地点: "地點",
    时间: "時間",
    机构: "機構",
  },
  // 联系我们
  "Contact US": {
    公司名: "錦欣生殖醫療集團有限公司：",
    投资者关系管理咨询: "投資者關係管理諮詢：",
    公关及合作咨询: "企業新聞及公關諮詢：",
    锦欣学苑: "錦欣學苑聯繫電話：",
    人才招聘咨询: "人才招聘諮詢：",
    "24小时热线": "錦欣生殖24小時客服諮詢熱線：",
    地址: "地址：",
    地址内容: "中國四川省成都市錦江區靜沙北路301號：",
    img: require("@/assets/images/contact/contact_img01.jpg"),
  },
  // 新闻中心副标题默认文案
  锦欣生殖医疗集团有限公司: "錦欣生殖醫療集團有限公司",
  // ...zhLocale
};
