import axios from "axios";
import { Notification} from 'element-ui'
import VueAxios from "./axios";

// 创建 axios 实例
const service = axios.create({
  // baseURL: 'http://hplus.wywzjs.com',
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 6000, // 请求超时时间
});

const err = (error) => {
  if (error.response) {
    let data = error.response.data;
    const token = "";
    console.log("------异常响应------", token);
    console.log("------异常响应------", error.response.status);
    switch (error.response.status) {
      case 403:
        Notification.error({ title: '系统提示', message: '拒绝访问', duration: 4000})
        break;
      case 404:
        Notification.error({ title: '系统提示', message: '很抱歉，资源未找到!', duration: 4000})
        break;
      case 504:
        Notification.error({ title: '系统提示', message: '网络超时'})
        break;
      case 401:
        Notification.error({ title: '系统提示', message: '未授权，请重新登录', duration: 4000})
        // if (token) {
        //   store.dispatch('Logout').then(() => {
        //     setTimeout(() => {
        //       window.location.reload()
        //     }, 1500)
        //   })
        // }
        break;
      default:
        Notification.error({
          title: '系统提示',
          message: !data.message ? "系统异常，请联系管理员" : data.message,
          duration: 4000
        })
        break;
    }
  }
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = "";
    if (token) {
      config.headers["X-Access-Token"] = token; // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    if (config.method === "get") {
      config.params = {
        _t: Date.parse(new Date()) / 1000,
        ...config.params,
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// response interceptor
service.interceptors.response.use((response) => response.data, err);

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service);
  },
};

export { installer as VueAxios, service as axios };
