// import enLocale from 'element-ui/lib/locale/lang/en'

export default {
  lang: "English",
  // 首页导航
  indexnav: {
    了解更多锦欣动态: "Learn more",
    了解招聘詳情: "Learn more",
    Home: "Home", // 网站首页
    "About jinxin": "About jinxin", // 关于锦欣
    "Corporate Profile": "Corporate Profile", // 关于锦欣下拉菜单--企业简介
    Milestones: "Milestones", //  关于锦欣下拉菜单--里程碑
    "Awards & Recognitions": "Awards & Recognitions", // 关于锦欣下拉菜单--企业荣誉
    CSR: "Social Responsibility", // 关于锦欣下拉菜单--社会责任
    executives: "executives", // 高管信息

    // --------------------------------------------------------

    "Our Medical Institutions": "Our Medical Institutions", // 旗下医院
    "Our Business": "Our Business", // 开展业务
    "News Center": "News Center", // 新闻中心
    Careers: "Careers", // 人才招聘

    // 投资者关系 --------------------------------------------------------

    "Investor Relations": "Investor Relations", // 投资者关系
    "Corporate Information": "Corporate Information", // 投资者关系下拉菜单--公司资料
    "Listing Documents": "Listing Documents", // 投资者关系下拉菜单--上市文件
    "Financial Reports": "Financial Reports", // 投资者关系下拉菜单--财务报告
    "Corporate Presentation": "Corporate Presentation", // 投资者关系下拉菜单--公司推介材料
    "Stock Chart": "Stock Chart", // 投资者关系下拉菜单--股票信息
    "IR Calendar": "IR Calendar", // 投资者关系下拉菜单--投资者关系日历
    "Press Release": "Press Release", // 投资者关系下拉菜单--新闻稿
    "Corporate Governance": "Corporate Governance", // 投资者关系下拉菜单--企业管治
    "Announcements and Circulars": "Announcements and Circulars", // 投资者关系下拉菜单--公告及通函
    "Email Subscription": "Email Subscription", // 投资者关系下拉菜单--邮件订阅
    "Investor Enquiries": "Investor Enquiries", // 投资者关系下拉菜单--投资者查询

    // 联系我们--------------------------------------------------------

    "Contact Us": "Contact Us", // 联系我们
    "Customer Enquiries": "Customer Enquiries", // 联系我们下拉菜单--客户咨询
    "Follow us": "Follow us", // 联系我们下拉菜单--关注我们
    // 页面右侧导航--------------------------------------------------------

    Online: "Online", // 在线沟通
    电话咨询: "电话咨询", // 电话咨询
  },
  // 首页轮播图
  indexbanner: [
    { banner: require("@/assets/images/banner/en_banner_00.jpg") },
    { banner: require("@/assets/images/banner/en_banner_01.jpg") },
    { banner: require("@/assets/images/banner/en_banner_02.gif") },
  ],
  // 首页
  index: {
    // 公司介绍
    jieshao:
      "Jinxin Fertility has became a leading assisted reproductive services provider in China and the United States which we have achieved through accumulati",
    aboutusimg: require("../assets/images/index/en_index_03.jpg"),
    "Learn more": "Learn more", // 了解更多
    "Our Medical Institutions": "Our Medical Institutions", // 旗下医院
    // 医院列表
    hospitalsList: [
      {
        id: 0,
        name: "Chengdu Xinan Hospital",
        img: require("../assets/images/index/1-21042109311SD.jpg"),
        address: "Chengdu, Sichuan Province",
        href: "http://www.cdxnfk.com/",
        show: true,
      },
      {
        id: 1,
        name: "Jinjiang IVF Center",
        img: require("../assets/images/index/1-210421101F0522.jpg"),
        address: "Chengdu, Sichuan Province",
        href: "http://www.jjqfybjy.com/",
        show: false,
      },
      {
        id: 2,
        name: "Shenzhen Zhongshan Hospital",
        img: require("../assets/images/index/1-21042110154D48.jpg"),
        address: "Shenzhen, Guangdong Province",
        href: "https://www.zsmw.net/",
        show: false,
      },
      {
        id: 3,
        name: "Wuhan Jinxin Hospital",
        img: require("../assets/images/index/hosp_img_03.jpg"),
        address: "Wuhan",
        href: "http://www.wuhanjinxin.com/",
        show: false,
      },
      {
        id: 4,
        name: "HRC Medical",
        img: require("../assets/images/index/1-2104211023222H.jpg"),
        address: "California, USA",
        href: "http://www.hrc-fertility.net/",
        show: false,
      },
      {
        id: 5,
        name: "HRC Southeast Asia Center",
        img: require("../assets/images/index/1-210323120H40-L.jpg"),
        address: "Southeast Asia",
        href: "http://www.jinruifertility.cn/",
        show: false,
      },
    ],
    "News Center": "News Center", // 新闻中心
    Careers: "Careers", // 人才招聘
    CareersMsg: {
      // 人才招聘下面的标语
      msg1:
        "We respect the unique value of each employee, and encourage both employee value and enterprise value to grow together. We explore the potential of each employee's connotation, training, shaping and activating the vitality of employees in the workplace; We admire every employee's passion for dreams, develop the professional spirit and",
      msg2: "professionalism of every employee, and achieve every big dream!",
      msg3:
        "We welcome everyone who is eager to fulfill their dreams, help the newborn, and protect the smile of their parents!",
    },
    footer: {
      JinXin: "JinXin", // 名字
      Address:
        "Address：No. 301, North Jingsha Road,Jinjiang District, Chengdu, Sichuan, China", // 地址
      telimg: require("../assets/images/index/en_index-tel-49.jpg"), // 24小时热线
      "WeChat Number": "WeChat Number", // 微信服务号
      guanzhujinxin: "About JinXin", // 关注锦欣

      "Investor Relations": "Investor Relations", // 投资者关系

      touzizheguanxi: "JinXin Fertility's Investor Relations", // 锦欣生殖投资者关系
    },
  },
  // 关于我们 ----------------------------------------------------------------------
  aboutus: {
    // 高管信息
    公司管理团队: "Company Management team",
    "About Us": "About Us", // 关于我们标题
    // ---跳转
    Home: "Home", // 返回首页
    "Corporate Profile": "Corporate Profile", // 企业简介
    // ---企业简介内容
    msg1:
      "Since 1951, Jinxin Reproductive has developed into a leading assisted reproductive service organization in China and the United States, and continues to expand our network to provide more and more high-end services of higher value. The group includes five centers of assisted reproductive technology: Chengdu Xi Nan, Shenzhen Zhongshan, Wuhan Jinxin, HRC Fertility and Jinrui Medical Center.", // 内容1
    msg2:
      "The establishment of the Group dates back to 2003, when a team of senior and dedicated doctors and managers agreed to set up Jinjiang IVF Center in Chengdu, Sichuan Province to focus on assisted reproductive services and related treatments. Shenzhen zhongshan hospital was founded in May 2004 by a number of personal founders including Mr Zeng yong, one of our senior management members. Shenzhen Zhongshan Hospital provides assisted reproduction and other complementary medical services and assisted in the birth of the first baby in Shenzhen through IVE-ET technology and IVF combined with ICSI technology.", // 内容2
    msg3:
      'According to Frost Sullivan Report, our in-network assisted reproductive care facilities in China ranked third in the assisted reproductive services market in China in 2018, performing 20,958 cycles of external fertilization (" IVF "), with a market share of about 3.1%. According to the same index, the agency also ranked first among non-state assisted reproductive service institutions in China in 2018.', // 内容3
    msg4:
      'Through the acquisition of HRC Management, which manages HRC Medical, HRC Fertility has enhanced our ability to provide high-value assisted reproductive services to international patients, particularly those from China. HRC Fertility is a leading full-service assisted reproductive service in the United States with over 30 years of experience. In addition to the similar IVF services we provide in China, we also provide pre-implantation genetic screening (" PGS ") services and pre-implantation genetic diagnosis (" PGD ") services through third party clinics or institutions. HRC Fertility ranked first among all assisted reproductive service providers in the United States in terms of IVF treatment cycles for Chinese outbound assisted reproductive service patients traveling to the United States for treatment in 2017.', // 内容3
    // 医院列表
    hospitals: {
      "Chengdu Xinan Hospital": "Chengdu Xinan Hospital", // 成都西囡妇科医院
      "Shenzhen Zhongshan Hospital": "Shenzhen Zhongshan Hospital", // 深圳中山泌尿外科医院
      "Wuhan Jinxin Hospital": "Wuhan Jinxin Hospital", // 武汉锦欣中西医结合妇产医院
      "HRC Medical": "HRC Medical", // 美国HRC Fertility
      "HRC Southeast Asia Center": "HRC Southeast Asia Center", // 锦瑞医疗中心(美国HRC东南亚中心)
    },
    // 四个行业第一
    fourTitle: "The Brand's Four 'Tops' in Industry", // 标题
    info1: "The top listed company of professional ART service in Asia",
    info2: "Ranking top in market share of non-state-owned ART institutes",
    info3: "Ranking top in market share of the United States west coast",
    info4: "Ranking top in serving Chinese patients overseas",
    // 全球知名品牌 树立行业标杆
    flagTitle: "The World-leading Brand and the Industrial Benchmark", // 标题
    flageInfo1:
      "Jinxin Fertility Specializes in ART Related Service and Treatment", // 内容1
    flageInfo2:
      "Jinxin Fertility ranked No.1 in ARS market in Sichuan Province, Shenzhen and the West Coast of the United States in 2019, and performed 27,854 IVF cycles",
    // 四个优势
    "70 Years of Jinxin Medical Quality Assurance":
      "70 Years of Jinxin Medical Quality Assurance", // 70年锦欣妇儿经验
    "The Largest VIP Medical Service System":
      "The Largest VIP Medical Service System", // 规模较大的VIP医疗服务体系
    "High success rate of 20-year patient reputation accumulation":
      "High success rate of 20-year patient reputation accumulation", // 20年患者口碑积淀的高成功率
    "First listed stock of assisted reproductive service (ARS) in China":
      "First listed stock of assisted reproductive service (ARS) in China", // 国内辅助生殖第一股
    "International Quality and the Largest Nationwide VIP Medical Service System":
      "International Quality and the Largest Nationwide VIP Medical Service System", // 规模较大且具有国际化的医疗服务体系
    "BESPOKE MEDICAL PLAN": "BESPOKE MEDICAL PLAN", // 定制医疗方案
    "Meet diversified demands": "Meet diversified demands", // 满足多样就诊需求

    "VIP QUICK ACCESS SERVICE": "VIP QUICK ACCESS SERVICE", // 尊享便捷服务
    "24hour":
      "24-hour pre-diagnosis consulting VIP elevator Premium bespoke nutritional dietaryExclusive car park One-on-one exclusive doctor Other additional services", // 24小时诊前医疗咨询、VIP专属电梯、高级定制营养膳食、专属停车场、一对一专属医师、其他附加服务
    "PERSONAL COUNSELLING": "PERSONAL COUNSELLING", // 全程贴身咨询
    "Personalized exclusive VIP service": "Personalized exclusive VIP service", // 个性私享的尊贵服务
    "CELEBRITY-CLASS DIAGNOSIS": "CELEBRITY-CLASS DIAGNOSIS", // 明星级医生诊疗
    clinical:
      "Service provided by the most clinical-experienced experts and assistants", // 院内临床经验最为丰富的专家、服务经验最为丰富的医疗助理级个案管理师服务
    // 高成功率
    Hight: "HIGH SUCCESS RATE",
    ensure: "ART quality assurance of 20 years", // 20年辅助生殖领域质量保证
    Experts: "Senior authority experts in China", // 中美高年资权威专家
    Experts2: "and the United States", // 中美高年资权威专家
    laboratory: "The world leading embryo lab", // 国际高标准胚胎实验室
  },
  // 里程碑 ----------------------------------------------------------------------
  milestone: {
    // 跳转
    Home: "Home", // 网站首页
    "About JinXin": "About JinXin", // 关于锦欣
    Milestones: "Milestones", // 里程碑
    // 好孕一站式医疗管理服务
    management: "ONE-STOP MEDICAL MANAGEMENT SERVICE",
    //  全球领先辅助生殖机构
    leading: "A WORLD-LEADING ART INSTITUTE",
    // 历程
    info1: {
      year: "2020", // 年份
      infor:
        "Acquisition of Wuhan Huangpu Chinese and Western Medicine Gynecology Hospital and Renamed it as Wuhan Jinxin Chinese and Western Medicine Gynecology Hospital", // 内容
    },
    info2: {
      year: "2020", // 年份
      infor: "Establishment of HRC Southeast Asia Center in Laos", // 内容
    },
    info3: {
      year: "2019", // 年份
      infor: "Jinxin Reproduction was listed in Hong Kong on June 25, 2019", // 内容
    },
    info4: {
      year: "2018", // 年份
      infor:
        "Sinyin Investment, Sequoia Capital China, Southern Creation and Wuxi AppTec invested in the Group as pre-IPO investors", // 内容
    },
    info5: {
      year: "2018", // 年份
      infor:
        "Acquisition of HRC Fertility, the biggest reproductive center in Los Angeles, the United States", // 内容
    },
    info6: {
      year: "2017", // 年份
      infor: "Acquisition of Shenzhen Zhongshan Urology Hospital", // 内容
    },
    info7: {
      year: "2013", // 年份
      infor: "Establishment of Chengdu Xinan Gynecology Hospital", // 内容
    },
    info8: {
      year: "2000", // 年份
      infor: "Establishment of Reproductive Center", // 内容
    },
    info9: {
      year: "1999", // 年份
      infor: "Establishment of Fertility Clinic", // 内容
    },
    info10: {
      year: "1951", // 年份
      infor: "Establishment of Chengdu No.2 Maternity and Child Health Station", // 内容
    },
  },
  // 社会责任----------------------------------------------------------------------
  responsibility: {
    // 跳转
    Home: "Home", // 网站首页
    "About JingXin": "About JingXin", // 关于锦欣
    "Social Responsibility": "Social Responsibility", // 社会责任
    // 选择年份'Select Year'
    "Select Year": "Select Year",
  },
  //  企业荣誉----------------------------------------------------------------------
  Honor: {
    // 荣誉列表
    title: "Name of Project：",
    certification: "Accreditations：",
    time: "Issue time：",
    institutions: "Issuing Body：",
  },
  // 旗下医院----------------------------------------------------------------------
  hospitals: {
    title: "Our Medical Institutions", // 标题
    "Self-owned": "Self-owned", // 自有医院
    // 国HRC东南亚中心（锦瑞医疗中心）
    "HRC Southeast Asia Center": "HRC Southeast Asia Center",
    //  共同管理
    "Jointly-managed": "Jointly-managed",
  },
  // 开展业务----------------------------------------------------------------------
  "Our Business": {
    businessList: [
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_03.jpg"),
        // 标题
        title: "ARS",
        // 内容
        info:
          "We primarily provide our patients with two treatment solutions: (i) artificial insemination (AI), which can be performed with either husband’s sperm (AIH) or a donor sperm (AID); and (ii) IVF technology, whereby fertilization is achieved through conventional in vitro fertilization and embryo transfer (IVF-ET) or IVF with intracytoplasmic sperm injection (ICSI). In addition, we also offer related services such as nutrition guidance, Chinese medicine treatment and psychological counseling to support the ARS.",
      },
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_02.jpg"),
        // 标题
        title: "Management Services",
        // 内容
        info:
          "During the Track Record Period, we provided joint management services to Jinjiang IVF Center and Jinxin Fertility Center, the fertility center of Jinxin Women and Children Hospital, in return for management service fees pursuant to our IVF specialty collaboration agreements with Jinjiang District Maternity and Child Health Hospital and Jinxin Women and Children Hospital, respectively. In March 2018, we terminated our management services to Jinxin Fertility Center to optimize our business structure and resources, and subsequently the business of Jinxin Fertility Center and the related IVF specialty collaboration agreement were also terminated. We acquired HRC Management in December 2018, which has been providing management services to HRC Medical in the United States pursuant to an MSA since July 2017.",
      },
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_01.jpg"),
        // 标题
        title: "Ancillary Medical Services",
        // 内容
        info:
          "At Shenzhen Zhongshan Hospital, we also provide medical services in the areas of gynecology, urology and internal medicine.These medical services are designated as our ancillary medical services.",
      },
    ],
  },
  // 公司资料------------------------------------------------------------
  "Corporate Information": {
    // 左侧列表
    Listleft: [
      // 注册办事处
      {
        // 标题
        title: "Registered office",
        // 内容
        info: [
          "Cricket Square, Hutchins Drive",
          "P.O. Box 2681, Grand Cayman",
          "KY1-1111",
          "Cayman Islands",
        ],
      },
      // 中国总部及主要营业地点
      {
        // 标题
        title: "Headquarter and Principal Place of Business in PRC",
        // 内容
        info: [
          "No. 301, North Jingsha Road",
          "Jinjiang District, Chengdu",
          "Sichuan, China",
        ],
      },
      // 香港主要营业地点
      {
        // 标题
        title: "Principal Place of Business in Hong Kong",
        // 内容
        info: [
          "Unit 1907B,19/F, International Commerce Center,",
          "1 Austin Road West, Kowloon,",
          "Hong Kong",
        ],
      },
      // 公司网站
      {
        // 标题
        title: "Company’s Website",
        // 内容
        info: ["www.jxr-fertility.com"],
      },
      // 联席公司秘书
      {
        // 标题
        title: "Joint Company Secretaries",
        // 内容
        info: ["Ms. Zhai Yangyang", "Mr. Lee Kwok Fai Kenneth"],
      },
      // 授权代表
      {
        // 标题
        title: "Authorized Representatives",
        // 内容
        info: ["Mr. Lee Kwok Fai Kenneth"],
      },
      // 审核及风险管理委员会
      {
        // 标题
        title: "Audit and Risk Management Committee",
        // 内容
        info: [
          // '叶长青先生（主席）',
          // '方敏先生',
          // '胡喆女士',
          // '庄一强博士',
          // '王啸波先生'
          "Mr. Ye Changqing (Chairman)",
          "Mr. Fang Min",
          "Ms. Hu Zhe",
          "Dr. Chong Yat Keung",
          "Mr. Wang Xiaobo",
        ],
      },
    ],
    // 右侧列表
    Listright: [
      // 薪酬委员会
      {
        // 标题
        title: "Remuneration Committee",
        // 信息
        info: [
          "Dr. Chong Yat Keung (Chairman)",
          "Mr. Dong Yang",
          "Mr. Fang Min",
          "Mr. Wang Xiaobo",
          "Mr. Ye Changqing",
        ],
      },
      // 提名委员会
      {
        // 标题
        title: "Nomination Committee",
        // 信息
        info: [
          "Mr. Zhong Yong (Chairman)",
          "Dr. John G. Wilcox",
          "Dr. Chong Yat Keung",
          "Mr. Wang Xiaobo",
          "Mr. Ye Changqing",
        ],
      },
      // 战略决策委员会
      {
        // 标题
        title: "Strategic Decisions Committee",
        // 信息
        info: [
          "Mr. Zhong Yong (Chairman)",
          "Dr. John G. Wilcox",
          "Mr. Dong Yang",
          "Mr. Fang Min",
          "Mr. Li Jianwei",
        ],
      },
      // 医疗品质控制与科研发展委员会
      {
        // 标题
        title: "Medical Quality Control and R&D Committee",
        // 信息
        info: ["Dr. John G. Wilcox", "Dr. Chong Yat Keung"],
      },
      // 香港证券登记处
      {
        // 标题
        title: "Hong Kong Share Registrar",
        // 信息
        info: ["Computershare Hong Kong Investor Services Limited"],
      },
    ],
  },
  // 上市文件
  "Listing Documents": {
    // 点击图片跳转地址
    href:
      "https://files.services/files/485/2019/0613/20190613065707_54587736_en.pdf",
    img:
      "https://files.services/files/485/2019/0613/20190613070057_50583548_en.png",
  },
  // 股票信息
  stockmsg: {
    日期: "Date",
    时间: "Time",
    港币: "HKD",
    "升/跌": "Change",
    "升/跌(%)": "%Change",
    前收市价: "Previous Close",
    开盘价: "Open",
    今日最高: "Day High",
    今日最低: "Day Low",
    今日波幅: "DAY RANGE",
    "52周波幅": "52WK RANGE",
    市值: "MARKET CAP",
    交易单位: "BOARD LOT",
    成交量: "VOLUME",
    成交额: "TURNOVER",
    目前市盈率: "CURRENT P/E RATIO (TTM)",
    周息率: "YIELD",
    百万股: "0M",
    百万: "M",
    // 趋势图
    "1个月": "1M",
    "3个月": "3M",
    "1年": "1Y",
    今年: "YTD",
    全部: "All",
    亿: "0B",
  },
  // 企业管治
  corporategovernance: {
    董事名单: "List of Directors and their Role and Function",
    董事名单与其角色和职能: "LIST OF DIRECTORS AND THEIR ROLE AND FUNCTION",
    薪酬委员会的职权范围: "Terms of Reference of the Remuneration Committee",
    薪酬委员会职权范围: "Terms of Reference of the Remuneration Committee",
    提名委员会的职权范围: "Terms of Reference of the Nomination Committee",
    审核委员会的职权范围: "Terms of Reference of the Audit Committee",
    审核及风险管理委员会职权范围:
      "Terms of Reference of the Audit and Risk Management Committee",
  },
  // 邮件订阅
  email: {
    // 标语
    title:
      "If you want to get the most updated information of our company, please provide the following information.",
    From: {
      //    姓名
      姓名: "Name",
      // 邮箱地址
      电邮地址: "E-mail Address",
      // 公司
      公司: "Company",
      // 职位
      职位: "Job Title",
      // 地区
      地区: "Region",
      // 选择地区占位符
      请选择: "Please select",
      // 联络电话
      联络电话: "Contact No.",
      // 联络电话内线
      内线: "Ext",
      // 语言
      语言: "Language",
      // 选项
      英文: "English",
      中文: "Chinese",
      "中文&英文": "English & Chinese",
      // 提交
      提交: "Submit",
      // 验证码
      验证码: "VerificationCode",
    },
  },
  // 投资者关系日历
  touzizheguanxirili: {
    日期: "Date",
    活动: "Event",
    地点: "Venue",
    时间: "Time",
    机构: "Organizer",
  },
  // 联系我们
  "Contact US": {
    公司名: " Jinxin fertility group limited",
    投资者关系管理咨询: " Investment consultation：",
    公关及合作咨询: " Corporate news and public relations consulting：",
    人才招聘咨询: "Cooperation consultation：",
    锦欣学苑: "Jinxin Academy Telephone：",
    "24小时热线": " Customer Service Center 24-Hour Hotline：",
    地址: " Address：",
    地址内容:
      "No. 301, North Jingsha Road, Jinjiang District, Chengdu, Sichuan, China",
    img: require("@/assets/images/contact/en_contact_img01.jpg"),
  },
  // 新闻中心副标题默认文案
  锦欣生殖医疗集团有限公司: "Jinxin Fertility Group Limited",
  //   ...enLocale
};
