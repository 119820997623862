// import zhLocale from 'element-ui/lib/locale/lang/en'

export default {
  lang: "中文",
  // 首页公共导航
  indexnav: {
    // 首页按钮
    了解更多锦欣动态: "了解更多锦欣动态",
    了解招聘詳情: "了解招聘详情",
    Home: "网站首页", // 网站首页
    // --------------------------------------------------------

    "About jinxin": "关于锦欣", // 关于锦欣
    "Corporate Profile": "企业简介", // 关于锦欣下拉菜单--企业简介
    Milestones: "里程碑", //  关于锦欣下拉菜单--里程碑
    "Awards & Recognitions": "企业荣誉", // 关于锦欣下拉菜单--企业荣誉
    CSR: "社会责任", // 关于锦欣下拉菜单--社会责任
    executives: "高管信息", // 高管信息
    // --------------------------------------------------------
    "Our Medical Institutions": "旗下医院", // 旗下医院
    "Our Business": "开展业务", // 开展业务
    "News Center": "新闻中心", // 新闻中心
    Careers: "人才招聘", // 人才招聘
    // 投资者关系 --------------------------------------------------------
    "Investor Relations": "投资者关系", // 投资者关系
    "Corporate Information": "公司资料", // 投资者关系下拉菜单--公司资料
    "Listing Documents": "上市文件", // 投资者关系下拉菜单--上市文件
    "Financial Reports": "财务报告", // 投资者关系下拉菜单--财务报告
    "Corporate Presentation": "公司推介材料", // 投资者关系下拉菜单--公司推介材料
    "Stock Chart": "股票信息", // 投资者关系下拉菜单--股票信息
    "IR Calendar": "投资者关系日历", // 投资者关系下拉菜单--投资者关系日历
    "Press Release": "新闻稿", // 投资者关系下拉菜单--新闻稿
    "Corporate Governance": "企业管治", // 投资者关系下拉菜单--企业管治
    "Announcements and Circulars": "公告及通函", // 投资者关系下拉菜单--公告及通函
    "Email Subscription": "邮件订阅", // 投资者关系下拉菜单--邮件订阅
    "Investor Enquiries": "投资者查询", // 投资者关系下拉菜单--投资者查询

    // 联系我们--------------------------------------------------------

    "Contact Us": "联系我们", // 联系我们
    "Customer Enquiries": "客户咨询", // 联系我们下拉菜单--客户咨询
    "Follow us": "关注我们", // 联系我们下拉菜单--关注我们

    // 页面右侧导航--------------------------------------------------------

    Online: "在线沟通", // 在线沟通
    电话咨询: "电话咨询", // 电话咨询
  },
  // 首页轮播图
  indexbanner: [
    { banner: require("../assets/images/banner/banner_00.jpg") },
    { banner: require("../assets/images/banner/banner_01.jpg") },
    { banner: require("../assets/images/banner/banner_02.gif") },
  ],
  // 首页
  index: {
    jieshao:
      "自1951年发展至今，锦欣生殖已发展成为中美领先的辅助生殖服务机构，并且不断地扩大我们的网络以提供越来越高价值的高端服务，集团旗下包括成都西囡、深圳中山、武汉锦欣、美国HRC Fertility及锦瑞医疗中心（美国HRC东南亚中心） 五大辅助生殖技术中心。",
    aboutusimg: require("../assets/images/index/index_03.jpg"), // 关于我们图片
    "Learn more": "了解更多more", // 了解更多
    "Our Medical Institutions": "旗下医院", // 旗下医院标题
    // 医院列表
    hospitalsList: [
      {
        id: 0,
        name: "成都西囡妇科医院",
        img: require("../assets/images/index/1-21042109311SD.jpg"),
        address: "四川成都",
        href: "http://www.cdxnfk.com/",
        show: true,
      },
      {
        id: 1,
        name: "成都市锦江区妇幼保健院生殖医学中心",
        img: require("../assets/images/index/1-210421101F0522.jpg"),
        address: "四川成都",
        href: "http://www.jjqfybjy.com/",
        show: false,
      },
      {
        id: 2,
        name: "深圳中山泌尿外科医院",
        img: require("../assets/images/index/1-21042110154D48.jpg"),
        address: "广东深圳",
        href: "https://www.zsmw.net/",
        show: false,
      },
      {
        id: 3,
        name: "武汉锦欣中西医结合妇产医院",
        img: require("../assets/images/index/hosp_img_03.jpg"),
        address: "武汉",
        href: "http://www.wuhanjinxin.com/",
        show: false,
      },
      {
        id: 4,
        name: "美国HRC",
        img: require("../assets/images/index/1-2104211023222H.jpg"),
        address: "[美国·加利福尼亚州]",
        href: "http://www.hrc-fertility.net/",
        show: false,
      },
      {
        id: 5,
        name: "美国HRC东南亚中心（锦瑞医疗中心）",
        img: require("../assets/images/index/1-210323120H40-L.jpg"),
        address: "老挝",
        href: "http://www.jinruifertility.cn/",
        show: false,
      },
    ],
    "News Center": "新闻中心", // 新闻中心
    Careers: "人才招聘", // 人才招聘
    CareersMsg: {
      // 人才招聘下面的标语
      msg1:
        "我们尊重每个员工所独具的价值，鼓励员工价值与企业价值共同成长；我们发掘每个员工所内涵的潜能，培训、塑造、激活员",
      msg2:
        "工的职场生命力；我们敬佩每个员工对梦想的激情，养成每个员工的职业精神和专业素养，成就每一个大小梦想！",
      msg3: "我们欢迎每一个渴望践行梦想，助力新生孕育，守护父母微笑的你!",
    },
    footer: {
      JinXin: "锦欣生殖", // 名字
      Address: "地址：中国成都市锦江区静沙北路301号", // 地址
      telimg: require("../assets/images/coomon/index-tel-49.jpg"), // 24小时热线
      "WeChat Number": "微信服务号", // 微信服务号
      "Investor Relations": "投资者关系", // 投资者关系

      guanzhujinxin: "关注锦欣生殖", // 关注锦欣生殖

      touzizheguanxi: "锦欣生殖投资者关系", // 锦欣生殖投资者关系
    },
  },
  // 关于我们 ----------------------------------------------------------------------
  aboutus: {
    // 高管信息
    公司管理团队: "公司管理团队",
    "About Us": "关于我们", // 关于我们标题
    // ---跳转
    Home: "网站首页", // 返回首页
    "Corporate Profile": "企业简介", // 企业简介
    // ---企业简介内容
    msg1:
      "自1951年发展至今，锦欣生殖已发展成为中美领先的辅助生殖服务机构，并且不断地扩大我们的网络以提供越来越高价值的高端服务，集团旗下包括成都西囡、深圳中山、武汉锦欣、美国HRC Fertility及锦瑞医疗中心（美国HRC东南亚中心） 五大辅助生殖技术中心。", // 内容1
    msg2:
      "本集团成立可追溯至 2003 年， 当时资深及专责的医生及管理人员团队发愿在四川成都成立锦江 IVF 中心，以专注于辅助生殖服务及相关治疗。深圳中山医院于 2004 年 5 月由多名个人创办人（包括我们高级管理层成员之一曾勇先生）成立。深圳中山医院提供辅助生殖及其他辅助医疗服务，并协助深圳首个透过IVE-ET技术及IVF配合ICSI技术的婴儿诞生。", // 内容2
    msg3:
      "根据弗若斯特沙利文报告， 我们中国网络内的辅助生殖医疗机构于 2018 年在中国辅助生殖服务市场中排名第三，进行了 20,958 个体外受精（「IVF」）取卵周期，市场份额约为 3.1%。根据相同指标，该等机构亦在 2018 年中国非国有辅助生殖服务机构中排名第一。", // 内容3
    msg4:
      "本集团通过收购管理 HRC Medical 的 HRC Management，使我们增强了为国际患者（尤其是来自中国的患者）提供高价值辅助生殖服务的能力 HRC Fertility 为一间拥有逾30年经验的美国领先辅助生殖服务全方位服务机构。 除我们在中国提供的类似 IVF 服务外，亦提供植入前遗传筛查（「PGS」）服务及通过第三方诊所或机构提供植入前遗传学诊断（「PGD」）服务。按 2017 年为前往美国治疗的中国出境辅助生殖服务患者提供的IVF 治疗周期计， HRC Fertility 在美国所有辅助生殖服务机构中排名第一。", // 内容3
    // ---医院列表
    hospitals: {
      "Chengdu Xinan Hospital": "成都西囡妇科医院", // 成都西囡妇科医院
      "Shenzhen Zhongshan Hospital": "深圳中山泌尿外科医院", // 深圳中山泌尿外科医院
      "Wuhan Jinxin Hospital": "武汉锦欣中西医结合妇产医院", // 武汉锦欣中西医结合妇产医院
      "HRC Medical": "美国HRC Fertility", // 美国HRC Fertility
      "HRC Southeast Asia Center": "锦瑞医疗中心(美国HRC东南亚中心)", // 锦瑞医疗中心(美国HRC东南亚中心)
    },
    // ---四个行业第一
    fourTitle: "四个行业“第一” 铸造金字招牌", // 标题
    info1: "亚洲第一家专业化辅助生殖服务的上市公司",
    info2: "非国有辅助生殖服务机构市场占有率第一",
    info3: "美国西部市场占有率第一",
    info4: "中国赴美患者服务数量美国第一",
    // ---全球知名品牌 树立行业标杆
    flagTitle: "全球知名品牌 树立行业标杆", // 标题
    flageInfo1: "锦欣生殖集团专注于辅助生殖服务及相关治疗", // 内容1
    flageInfo2:
      "锦欣生殖于2019年在四川省、深圳市及美国西部地区的辅助生殖服务市场中均排名第一，进行了27854个体外受精（IVF）取卵周期。",
    // 四个优势
    "70 Years of Jinxin Medical Quality Assurance": "70年锦欣妇儿经验", // 70年锦欣妇儿经验
    "The Largest VIP Medical Service System": "规模较大的VIP医疗服务体系", // 规模较大的VIP医疗服务体系
    "High success rate of 20-year patient reputation accumulation":
      "20年患者口碑积淀的高成功率", // 20年患者口碑积淀的高成功率
    "First listed stock of assisted reproductive service (ARS) in China":
      "国内辅助生殖第一股", // 国内辅助生殖第一股
    "International Quality and the Largest Nationwide VIP Medical Service System":
      "规模较大且具有国际化的医疗服务体系", // 规模较大且具有国际化的医疗服务体系
    "BESPOKE MEDICAL PLAN": "定制医疗方案", // 定制医疗方案
    "Meet diversified demands": "满足多样就诊需求", // 满足多样就诊需求

    "VIP QUICK ACCESS SERVICE": "尊享便捷服务", // 尊享便捷服务
    "24hour":
      "24小时诊前医疗咨询、VIP专属电梯、高级定制营养膳食、专属停车场、一对一专属医师、其他附加服务", // 24小时诊前医疗咨询、VIP专属电梯、高级定制营养膳食、专属停车场、一对一专属医师、其他附加服务
    "PERSONAL COUNSELLING": "全程贴身咨询", // 全程贴身咨询
    "Personalized exclusive VIP service": "个性私享的尊贵服务", // 个性私享的尊贵服务
    "CELEBRITY-CLASS DIAGNOSIS": "明星级医生诊疗", // 明星级医生诊疗
    clinical:
      "院内临床经验最为丰富的专家、服务经验最为丰富的医疗助理级个案管理师服务", // 院内临床经验最为丰富的专家、服务经验最为丰富的医疗助理级个案管理师服务
    // 高成功率
    Hight: "高成功率",
    ensure: "20年辅助生殖领域质量保证", // 20年辅助生殖领域质量保证
    Experts: "中美高年资权威专家", // 中美高年资权威专家
    laboratory: "国际高标准胚胎实验室", // 国际高标准胚胎实验室
  },
  // 里程碑
  milestone: {
    // 跳转
    Home: "网站首页", // 网站首页
    "About JinXin": "关于锦欣", // 关于锦欣
    Milestones: "里程碑", // 里程碑
    // 好孕一站式医疗管理服务
    management: "好孕一站式医疗管理服务",
    //  全球领先辅助生殖机构
    leading: "全球领先辅助生殖机构",
    // 历程
    info1: {
      year: "2020年", // 年份
      infor:
        "收购武汉黄浦中西医结合妇产医院， 更名为武汉锦欣中西医结合妇产医院", // 内容
    },
    info2: {
      year: "2020年", // 年份
      infor: "在老挝成立美国HRC东南亚中心", // 内容
    },
    info3: {
      year: "2019年", // 年份
      infor: "锦欣生殖于2019年6月25日在香港挂牌上市", // 内容
    },
    info4: {
      year: "2018年", // 年份
      infor:
        "信银投资、红杉资本中国、Southern Creation 及WuXi AppTec 以首次公开发售前投资者身份投资本集团", // 内容
    },
    info5: {
      year: "2018年", // 年份
      infor: "收购位于美国洛杉矶最大的生殖中心-美国加州HRC生殖中心", // 内容
    },
    info6: {
      year: "2017年", // 年份
      infor: "收购深圳中山泌尿外科医院成立", // 内容
    },
    info7: {
      year: "2013年", // 年份
      infor: "成都西囡妇科医院", // 内容
    },
    info8: {
      year: "2000年", // 年份
      infor: "生殖中心正式成立", // 内容
    },
    info9: {
      year: "1999年", // 年份
      infor: "不孕不育门诊成立", // 内容
    },
    info10: {
      year: "1951年", // 年份
      infor: "成都市第二区妇幼保健站成立", // 内容
    },
  },
  // 社会责任----------------------------------------------------------------------
  responsibility: {
    // 跳转
    Home: "网站首页", // 网站首页
    "About JingXin": "关于锦欣", // 关于锦欣
    "Social Responsibility": "社会责任", // 社会责任
    // 选择年份'Select Year'
    "Select Year": "选择年份",
    // 国HRC东南亚中心（锦瑞医疗中心）
    "HRC Southeast Asia Center": "国HRC东南亚中心（锦瑞医疗中心）",
    //  共同管理
    "Jointly-managed": "共同管理",
  },
  //  企业荣誉
  Honor: {
    // 荣誉列表
    title: "项目名称：",
    certification: "认证：",
    time: "颁发时间：",
    institutions: "颁发机构：",
  },
  // 旗下医院
  hospitals: {
    title: "旗下医院", // 标题
    "Self-owned": "自有医院", // 自有医院
    // 国HRC东南亚中心（锦瑞医疗中心）
    "HRC Southeast Asia Center": "国HRC东南亚中心（锦瑞医疗中心）",
    //  共同管理
    "Jointly-managed": "共同管理",
  },
  // 开展业务
  "Our Business": {
    businessList: [
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_03.jpg"),
        // 标题
        title: "辅助医疗服务",
        // 内容
        info:
          "在深圳中山医院，我们也于妇科、泌尿外科及内科领域提供医疗服务。该等医疗服务被指定为我们的配套医疗服务。",
      },
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_02.jpg"),
        // 标题
        title: "管理服务",
        // 内容
        info:
          " 于往绩记录期，我们根据分别与成都市锦江区妇幼保健院及四川锦欣妇女儿童医院订立的学科共建和合作协议向锦江生殖中心及锦欣生育中心（四川锦欣妇女儿童医院生殖健康与不孕症门诊）提供合作管理服务收取管理费。2018 年3 月，我们终止向锦欣生育中心提供的管理服务，以优化我们的业务结构及资源，随后锦欣生育中心的业务及有关学科共建和合作协议亦终止。我们于2018 年12 月收购HRC Management，其自2017 年7 月起一直根据管理服务协议在美国向HRC Medical 提供管理服务。 ",
      },
      // 列表1
      {
        // 图片
        img: require("@/assets/images/chapterseven/buss_img_01.jpg"),
        // 标题
        title: "辅助生殖服务",
        // 内容
        info:
          " 我们主要为患者提供两种治疗方案： (i) 人工授精(AI)，可以通过夫精(AIH) 或供精(AID) 进行；及(ii) IVF 技术，通过常规体外受精和胚胎移植(IVF-ET) 或通过卵胞浆内单精子注射(ICSI) 进行 IVF 来导致受精。于往绩记录期，我们主要提供常规IVF-ET 服务。此外，我们亦提供营养指导、中医治疗及心理辅导等相关服务，以支持辅助生殖服务。 ",
      },
    ],
  },
  // 公司资料------------------------------------------------------------
  "Corporate Information": {
    // 左侧列表
    Listleft: [
      // 注册办事处
      {
        // 标题
        title: "注册办事处",
        // 内容
        info: [
          "Cricket Square, Hutchins Drive",
          "P.O. Box 2681, Grand Cayman",
          "KY1-1111",
          "Cayman Islands",
        ],
      },
      // 中国总部及主要营业地点
      {
        // 标题
        title: "中国总部及主要营业地点",
        // 内容
        info: ["中国四川省", "成都市锦江区", "静沙北路301号"],
      },
      // 香港主要营业地点
      {
        // 标题
        title: "香港主要营业地点",
        // 内容
        info: ["香港", "西九龙柯士甸道西1号", "环球贸易广场19号1907B"],
      },
      // 公司网站
      {
        // 标题
        title: "公司网站",
        // 内容
        info: ["www.jxr-fertility.com"],
      },
      // 联席公司秘书

      {
        // 标题
        title: "联席公司秘书",
        // 内容
        info: ["翟扬扬女士", "李国辉先生"],
      },
      // 授权代表
      {
        // 标题
        title: "授权代表",
        // 内容
        info: ["李国辉先生"],
      },
      // 审核及风险管理委员会
      {
        // 标题
        title: "审核及风险管理委员会",
        // 内容
        info: [
          "叶长青先生（主席）",
          "方敏先生",
          "胡喆女士",
          "庄一强博士",
          "王啸波先生",
        ],
      },
    ],
    // 右侧列表
    Listright: [
      // 薪酬委员会
      {
        // 标题
        title: "薪酬委员会",
        // 信息
        info: [
          " 庄一强博士（主席）",
          "董阳先生",
          "方敏先生",
          "王啸波先生",
          "叶长青先生",
        ],
      },
      // 提名委员会
      {
        // 标题
        title: "提名委员会",
        // 信息
        info: ["钟勇先生（主席）", " 庄一强博士", "王啸波先生", "叶长青先生"],
      },
      // 战略决策委员会
      {
        // 标题
        title: "战略决策委员会",
        // 信息
        info: [
          "钟勇先生（主席）",
          "John G. Wilcox 医生",
          "董阳先生",
          "方敏先生",
          " 李建伟先生",
        ],
      },
      // 医疗品质控制与科研发展委员会
      {
        // 标题
        title: "医疗品质控制与科研发展委员会",
        // 信息
        info: ["John G. Wilcox 医生", "庄一强博士"],
      },
      // 香港证券登记处
      {
        // 标题
        title: "香港证券登记处",
        // 信息
        info: ["香港中央证券登记有限公司"],
      },
    ],
  },
  // 上市文件
  "Listing Documents": {
    // 点击图片跳转地址
    href:
      "https://files.services/files/485/2019/0613/20190613065812_03522122_sc.pdf",
    img:
      "https://files.services/files/485/2019/0613/20190613070000_50449292_sc.png",
  },
  // 股票信息
  stockmsg: {
    日期: "日期",
    时间: "时间",
    港币: "港币",
    "升/跌": "升/跌",
    "升/跌(%)": "升/跌 (%)",
    前收市价: "前收市价",
    开盘价: "开盘价",
    今日最高: "今日最高",
    今日最低: "今日最低",
    今日波幅: "今日波幅",
    "52周波幅": "52周波幅",
    市值: "市值",
    交易单位: "交易单位",
    成交量: "成交量",
    成交额: "成交额",
    目前市盈率: "目前市盈率",
    周息率: "周息率",
    百万股: "百万股",
    百万: "百万",
    // 趋势图
    "1个月": "1个月",
    "3个月": "3个月",
    "1年": "1年",
    今年: "今年",
    全部: "全部",
    亿: "亿",
  },
  // 企业管治
  corporategovernance: {
    董事名单: "董事名单和他们的地位和作用",
    董事名单与其角色和职能: "董事名单与其角色和职能",
    薪酬委员会的职权范围: "薪酬委员会的职权范围",
    薪酬委员会职权范围: "薪酬委员会职权范围",
    提名委员会的职权范围: "提名委员会的职权范围",
    审核委员会的职权范围: "审核委员会的职权范围",
    审核及风险管理委员会职权范围: "审核及风险管理委员会职权范围",
  },
  // 邮件订阅
  email: {
    // 标语
    title: "如果您希望收到本公司最新公布的资讯，请填妥以下表格",
    From: {
      //    姓名
      姓名: "姓名",
      // 邮箱地址
      电邮地址: "电邮地址",
      // 公司
      公司: "公司",
      // 职位
      职位: "职位",
      // 地区
      地区: "地区",
      // 选择地区占位符
      请选择: "请选择",
      // 联络电话
      联络电话: "联络电话",
      // 内线
      内线: "内线",
      // 语言
      语言: "语言",
      // 选项
      英文: "英文",
      中文: "中文",
      "中文&英文": "中文&英文",
      // 提交
      提交: "提交",
      // 验证码
      验证码: "验证码",
    },
  },
  // 投资者关系日历
  touzizheguanxirili: {
    日期: "日期",
    活动: "活动",
    地点: "地点",
    时间: "时间",
    机构: "机构",
  },
  // 联系我们
  "Contact US": {
    公司名: "锦欣生殖医疗集团有限公司：",
    投资者关系管理咨询: "投资者关系管理咨询：",
    公关及合作咨询: "企业新闻及公关咨询：",
    人才招聘咨询: "人才招聘咨询：",
    锦欣学苑: "锦欣学苑联系电话：",
    "24小时热线": "锦欣生殖24小时客服咨询热线：",
    地址: "地址：",
    地址内容: "中国四川省成都市锦江区静沙北路301号：",
    img: require("@/assets/images/contact/contact_img01.jpg"),
  },
  // ...zhLocale
  // 新闻中心副标题默认文案
  锦欣生殖医疗集团有限公司: "锦欣生殖医疗集团有限公司",
};
