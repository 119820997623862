import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueI18n from 'vue-i18n'

import locale from 'element-ui/lib/locale'
import zh from './zh'
import en from './en'
import tr from './tr'


Vue.use(VueI18n)
const messages = {
    en: {
        ...en,
    },
    zh: {
        ...zh,
    },
    tr: {
        ...tr
    }
};
const i18n = new VueI18n({
    locale: localStorage.lang || 'zh',
    messages,
});
locale.i18n((key, value) => i18n.t(key, value))
export default i18n;
