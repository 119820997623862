import Vue from "vue";
import Router from "vue-router";
import i18n from '@/i18n/index';

Vue.use(Router);

export default new Router({
  
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      // 主页
      path: "/",
      name: "index",
      meta: {
        title: '锦欣生殖医疗集团|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '锦欣生殖医疗集团|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '锦欣生殖医疗集团|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
      component: () => import("@/views/index.vue"),
    },
    {
      path: "/404",
      name: '404',
      component: () => import("@/views/exception/404"),
    },
    // 关于锦欣--不直接使用/aboutus 如果要跳转到关于锦欣默认页面 hash值为/aboutus/synopsis
    // {
    //   path: "/aboutus",
    //   name: '/aboutus',
    //   component: () => import("@/views/aboutus/default"),
    //   children: [
    //     // 里程碑
    //     {
    //       path: '/aboutus/milestone',
    //       name: '/aboutus/milestone',
    //       component: () => import("@/views/aboutus/milestone"),
    //       meta: {
    //         title: '里程碑|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //         content: {
    //           keywords: '里程碑|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //           description: '里程碑|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
    //         }
    //       },
    //     },
    //     // 企业荣誉
    //     {
    //       path: '/aboutus/Honor',
    //       name: '/aboutus/Honor',
    //       component: () => import('@/views/aboutus/Honor'),
    //       meta: {
    //         title: '企业荣誉|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //         content: {
    //           keywords: '企业荣誉|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //           description: '企业荣誉|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
    //         }
    //       },
    //     },
    //     // 社会责任
    //     {
    //       path: '/aboutus/responsibility',
    //       name: '/aboutus/responsibility',
    //       component: () => import('@/views/aboutus/responsibility'),
    //       meta: {
    //         title: '社会责任|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //         content: {
    //           keywords: '社会责任|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //           description: '社会责任|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
    //         }
    //       },
    //     },
    //     // 企业简介
    //     {
    //       path: '/aboutus/synopsis',
    //       name: '/aboutus/synopsis',
    //       component: () => import('@/views/aboutus/synopsis'),
    //       meta: {
    //         title: '企业简介|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //         content: {
    //           keywords: '企业简介|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //           description: '企业简介|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
    //         }
    //       },
    //     },
    //     // 高管信息
    //     {
    //       path: '/aboutus/executives',
    //       name: '/aboutus/executives',
    //       component: () => import('@/views/aboutus/executives'),
    //       meta: {
    //         title: '高管信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //         content: {
    //           keywords: '高管信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
    //           description: '高管信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
    //         }
    //       },
    //     }
    //   ]
    // },
    // 里程碑
    {
      path: '/aboutus/milestone',
      name: '/aboutus/milestone',
      component: () => import("@/views/aboutus/milestone"),
      meta: {
        title: '里程碑|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '里程碑|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '里程碑|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 企业荣誉
    {
      path: '/aboutus/Honor',
      name: '/aboutus/Honor',
      component: () => import('@/views/aboutus/Honor'),
      meta: {
        title: '企业荣誉|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '企业荣誉|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '企业荣誉|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 社会责任
    {
      path: '/aboutus/responsibility',
      name: '/aboutus/responsibility',
      component: () => import('@/views/aboutus/responsibility'),
      meta: {
        title: '社会责任|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '社会责任|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '社会责任|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 企业简介
    {
      path: '/aboutus/synopsis',
      name: '/aboutus/synopsis',
      component: () => import('@/views/aboutus/synopsis'),
      meta: {
        title: '企业简介|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '企业简介|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '企业简介|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 高管信息
    {
      path: '/aboutus/executives',
      name: '/aboutus/executives',
      component: () => import('@/views/aboutus/executives'),
      meta: {
        title: '高管信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '高管信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '高管信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 旗下医院
    {
      path: '/hospitals',
      name: '/hospitals',
      component: () => import("@/views/hospitals/default"),
      meta: {
        title: '旗下医院|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '旗下医院|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '旗下医院|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 开展业务
    {
      path: '/chapterseven',
      name: '/chapterseven',
      component: () => import('@/views/chapterseven/default'),
      meta: {
        title: '开展业务|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '开展业务|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '开展业务|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 新闻中心
    {
      path: '/article/list',
      name: '/article/list',
      component: () => import("@/views/news/default"),
      meta: {
        keepalive:true,
        title: '新闻中心|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '新闻中心|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '新闻中心|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    {

      path: '/newsinfo',
      name: '/newsinfo',
      component: () => import("@/views/news/newsinfo"),
      // meta: {
      //   title: '新闻中心|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
      //   content: {
      //     keywords: '新闻中心|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
      //     description: '新闻中心|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
      //   }
      // },
    },
    // 人才招聘
    {
      path: '/findjob',
      name: '/findjob',
      component: () => import('@/views/findjob/default'),
      meta: {
        title: '人才招聘|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '人才招聘|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '人才招聘|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 投资者关系
    {
      path: '/investors/default',
      name: '/investors/default',
      component: () => import('@/views/investors/default'),
      meta: {
        title: '投资者关系|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '投资者关系|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '投资者关系|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 公司资料
    {
      path: '/investors/companyprofile',
      name: '/investors/companyprofile',
      component: () => import('@/views/investors/companyprofile'),
      meta: {
        title: '公司资料|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '公司资料|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '公司资料|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 上市文件
    {
      path: '/investors/listingdocument',
      name: '/investors/listingdocument',
      component: () => import('@/views/investors/listingdocument'),
      meta: {
        title: '上市文件|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '上市文件|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '上市文件|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 财务报告
    {
      path: '/investors/financialreports',
      name: '/investors/financialreports',
      component: () => import('@/views/investors/financialreports'),
      meta: {
        title: '财务报告|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '财务报告|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '财务报告|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 公司推介资料
    {
      path: '/investors/materials',
      name: '/investors/materials',
      component: () => import('@/views/investors/materials'),
      meta: {
        title: '公司推介资料|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '公司推介资料|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '公司推介资料|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 股票信息
    {
      path: '/investors/stockmsg',
      name: '/investors/stockmsg',
      component: () => import('@/views/investors/stockmsg'),
      meta: {
        title: '股票信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '股票信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '股票信息|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 投资者关系日历
    {
      path: '/investors/investorcalendar',
      name: '/investors/investorcalendar',
      component: () => import('@/views/investors/investorcalendar'),
      meta: {
        title: '投资者关系日历|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '投资者关系日历|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '投资者关系日历|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 新闻稿
    {
      path: '/investors/newsrelease',
      name: '/investors/newsrelease',
      component: () => import('@/views/investors/newsrelease'),
      meta: {
        title: '新闻稿|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '新闻稿|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '新闻稿|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    //  企业管治
    {
      path: '/investors/corporategovernance',
      name: '/investors/corporategovernance',
      component: () => import('@/views/investors/corporategovernance'),
      meta: {
        title: '企业管治|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '企业管治|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '企业管治|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 公告及通函
    {
      path: '/investors/notice',
      name: '/investors/notice',
      component: () => import('@/views/investors/notice'),
      meta: {
        title: '公告及通函|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '公告及通函|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '公告及通函|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 邮件订阅
    {
      path: '/investors/email',
      name: '/investors/email',
      component: () => import('@/views/investors/email'),
      meta: {
        title: '邮件订阅|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '邮件订阅|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '邮件订阅|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    //  投资者查询
    {
      path: '/investors/query',
      name: '/investors/query',
      component: () => import('@/views/investors/query'),
      meta: {
        title: '投资者查询|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '投资者查询|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '投资者查询|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 联系我们
    {
      path: '/contactus/default',
      name: '/contactus/default',
      component: () => import('@/views/contactus/default'),
      meta: {
        title: '联系我们|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '联系我们|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '联系我们|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    // 关注我们
    {
      path: '/contactus/counsel',
      name: '/contactus/counsel',
      component: () => import('@/views/contactus/counsel'),
      meta: {
        title: '关注我们|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
        content: {
          keywords: '关注我们|专注辅助生殖、试管婴儿及其他辅助医疗服务。',
          description: '关注我们|专注辅助生殖、试管婴儿及其他辅助医疗服务。'
        }
      },
    },
    {
      path: "*",
      redirect: "/404",
    },
  ],
});

