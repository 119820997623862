import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 当前语言
    nowlanguage: (localStorage.getItem('lang')),
    // 判定是否是首页
    isindex: false,
    navTree:[],
    navLanguageKey:'',
    // 判定导航
    // navchange: 'false'
  },
  mutations: {
    // 设置当前语言
    setnowlanguage(state, language) {
      console.log(language);
      this.state.nowlanguage = language.command
    },
    // setnavchange(state, isshow) {
    //   console.log(isshow);
    //   this.navchange = isshow
    // }
  },
  actions: {},
  modules: {},
  
});
